import React from "react";
import "./DestinationsHeader.css";
import bg from "../../../Assets/Images/destinations.jpg";
const DestinationsHeader = () => {
  return (
    <div
      className="hero destinations-hero"
      style={{ backgroundImage: "url(" + bg + ")" }}
    >
      <h1 className="britania hero-title">Destinations</h1>
    </div>
  );
};

export default DestinationsHeader;
