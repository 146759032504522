import React from "react";
import "./IslandActivities.css";
import IslandActivitiesSlider from "./IslandActivitiesSlider";

const IslandActivities = (props) => {
  return (
    <div className="island-activities-wrapper">
      <div className="island-activities-header">
        <h2 className="island-activities-title">
          Things you can do at {props.island.Name}
        </h2>
        <p className="section-p">
          Utheemu Ganduvaru is the birthplace and home of Sultan Mohamed
          Thakurufaanu, one of the most revered heroes in Maldivian history.
          Utheemu Ganduvaru, which translates to Utheemu Palace is located in
          Utheemu in Haa Alifu Atoll, in the northernmost extremes of the
          Maldives.
        </p>
      </div>
      <div className="island-activities-slider">
        <IslandActivitiesSlider activities={props.island.special_activities} />
      </div>
    </div>
  );
};

export default IslandActivities;
