import * as React from "react"

const BookingThree = ({ title, titleId, ...props }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M244.25 500c-33.843 0-67.56-4.524-100.215-13.426-33.104-9.035-61.717-22.131-85.055-38.934l-9.39-6.765 54.384-107.027 12.859 9.654c16.4 12.292 35.708 21.986 57.397 28.804a224.448 224.448 0 0 0 67.362 10.34c24.169 0 43.088-4.484 56.244-13.32 11.79-7.914 17.277-18.373 17.277-32.96 0-11.935 0-43.628-73.52-43.628h-62.667v-89.71l79.218-89.657H76.62V0h352.53v88.338l-93.12 106.078c32.676 8.691 58.941 23.7 78.283 44.802 23.95 26.154 36.097 58.835 36.097 97.149 0 29.437-7.84 56.988-23.298 81.863-15.497 24.94-39.203 45.092-70.46 59.89C325.933 492.64 288.121 500 244.25 500ZM84.322 432.157c18.88 11.87 41.301 21.392 66.821 28.356a353.608 353.608 0 0 0 93.107 12.476c39.85 0 73.778-6.488 100.854-19.295 26.477-12.53 46.352-29.266 59.066-49.721 12.76-20.535 19.23-43.273 19.23-67.606 0-31.785-9.49-57.596-29.003-78.896-19.519-21.3-48.416-35.2-85.892-41.307l-24.05-3.93L402.14 78.157V27.01H103.631v59.35h214.417L205.936 213.262v52.465h35.656c83.09 0 100.532 38.418 100.532 70.639 0 23.41-10.11 42.56-29.233 55.38-17.673 11.882-41.67 17.91-71.299 17.91-25.626 0-51.014-3.89-75.46-11.58-19.09-6-36.638-14.02-52.346-23.911l-29.463 57.99Z"

    />
  </svg>
)

export default BookingThree
