import React from 'react';
import "./Populartags.css";

const PopularTags = () => {
  return (
    <div className='tags-widget'> 
          <div className='tag-cloud'>
        <span className='popular-tag'>Historical Landmark</span>
        <span className='popular-tag'>Frequent Whaleshark</span>
        <span className='popular-tag'>Tropical Vibe</span>
        <span className='popular-tag'>Eco-Friendly</span>
        <span className='popular-tag'>Bikini Beach</span>
        
    </div>
    </div>
  )
}

export default PopularTags
