import React, { useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import bg from "../../Assets/Images/login.jpg";
import logo from "../../Assets/Images/logo.png";

import "./Login.css";
import { login } from "../../components/Global/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLeftLong,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let user = await login(email, password);
      console.log(user);
      navigate("/dashboard");
    } catch (error) {
      setLoginFailed(true);
    }
  };
  if (sessionStorage.getItem("token")) return <Navigate to="/dashboard" />;

  return (
    <div
      className="login-page"
      style={{
        backgroundImage: "url(" + bg + ")",
      }}
    >
      <div className="login-box">
        <img className="logo" alt="MVSTAY" src={logo} />
        <form onSubmit={handleSubmit}>
          <div>
            <label className="login-label" htmlFor="email">
              Email
            </label>
            <div className="field-wrap">
              <span className="field-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <input
                type="email"
                name="email"
                id="email"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                className="field"
              ></input>
            </div>
          </div>
          <div>
            <label className="login-label" htmlFor="password">
              Password
            </label>
            <div className="field-wrap">
              <span className="field-icon">
                <FontAwesomeIcon icon={faLock} />
              </span>
              <input
                type="password"
                name="password"
                id="password"
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                className="field"
              ></input>
            </div>
          </div>
          {loginFailed && (
            <p className="login-failed">Incorrect Email or Password</p>
          )}

          <div>
            <input type="submit" name="login" className="BlueButton"></input>
          </div>
          <Link className="back-link" to={"/"}>
            <FontAwesomeIcon icon={faLeftLong} /> Back to Homepage
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
