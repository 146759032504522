import * as React from "react"

const BookingOne = ({ title, titleId, ...props }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M365.66 500H228.851V115.205H134.34V0h231.32v500ZM256.3 472.553h81.914V27.448H161.787v60.31H256.3v384.795Z"
    />
  </svg>
)

export default BookingOne
