import React from 'react';
import ReactStars from 'react-rating-stars-component';
import "./PropertyRelatedGuesthousesSlide.css";

const PropertyRelatedGuesthousesSlide = () => {
  return (
    <div className='property-related-slide'>
    <div className='property-related-image'
      style={{
        backgroundImage:
          "url(https://images.unsplash.com/photo-1614440455073-bd7fa8669eb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
      }}></div>
      <h2>Nabitte Retreat Inn Maldives</h2>
      <ReactStars
          size={20}
          value={3}
          edit={false}
          isHalf={true}
          color={"#ccc"}
          
          />
    </div>
  )
}

export default PropertyRelatedGuesthousesSlide
