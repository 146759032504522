import React from "react";
import IconGuestHouse from "../../../Assets/SVG/IconGuesthouse";
import IconHomeStay from "../../../Assets/SVG/IconHomeStay";
import "./InfoModal.css";

const InfoModal = () => {
  return (
    <div className="info-modal">
      <h1>Whats the difference between Guesthouse & Homestay?</h1>
      <div className="info-modal-grid">
        <div className="info-modal-item">
          <IconGuestHouse /> <h2>Guesthouse</h2>
        </div>
        <div className="info-modal-list">
          <ul>
            <li>Pricey compared to Homestay</li>
            <li>More inhouse features</li>
            <li>Bed & Breakfast included</li>
            <li>More amenities</li>
            <li>More amenities </li>
            <li>Meals provided </li>
          </ul>
        </div>
        <div className="info-modal-item">
          <IconHomeStay /> <h2>Homestay</h2>
        </div>
        <div className="info-modal-list">
          <ul>
            <li>Pricey compared to Homestay</li>
            <li>More inhouse features</li>
            <li>Bed & Breakfast included</li>
            <li>More amenities</li>
            <li>More amenities </li>
            <li>Meals provided </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
