import React, { useEffect, useState } from "react";
import SvgIcon from "../../Global/SvgIcon";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import "./AtollFeaturedActivity.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ActivityModalContent from "./ActivityModalContent";

const AtollFeaturedActivity = (props) => {
  const [activity, setActivity] = useState();

  useEffect(() => {
    setActivity(props.activity);
  }, [props.activity]);

  useEffect(() => {
    console.log(activity);
  }, [activity]);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const closeIcon = <FontAwesomeIcon icon={faTimesCircle} />;

  if (activity === undefined) return null;

  // console.log(props.svgIcon.data.attributes.SVGPath);
  return (
    <div>
      <div className="atoll-single-activity" onClick={onOpenModal}>
        <div className="atoll-single-activity-icon">
          <SvgIcon
            className=""
            icon={activity && activity.svg_icon.data.attributes.SVGPath}
            viewBox={activity && activity.svg_icon.data.attributes.viewbox}
          />
        </div>
        <div
          className="atoll-single-activity-image"
          style={{
            backgroundImage: "url(" + activity.Image.data.attributes.url + ")",
            // "url(https://images.unsplash.com/photo-1614440455073-bd7fa8669eb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
          }}
        ></div>
        <h3 className="atoll-single-activity-name">{activity.Name}</h3>
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeIcon={closeIcon}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <ActivityModalContent activity={props.activity} />
      </Modal>
    </div>
  );
};

export default AtollFeaturedActivity;
