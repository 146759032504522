import React from "react";
import AtollFacts from "../AtollFacts/AtollFacts";
import Gallery from "../../Global/Gallery/Gallery";
import "./AtollGallery.css";
import "./AtollHeader.css";

const AtollHeader = (props) => {
  if (props.atoll === undefined) return null;
  return (
    <div className="atoll-header">
      <div
        className="atoll-header-image"
        style={{ backgroundImage: "url(" + props.cover + ")" }}
      ></div>
      <div className="atoll-header-content">
        <div className="atoll-header-grid-col-1">
          <h1>{props.atoll.Name} Atoll</h1>
          <p className="paragraph">{props.atoll.Description}</p>
          {/* <div className="atoll-attraction-counter-grid">
            <AtollAttractionCounter />
            <AtollAttractionCounter />
            <AtollAttractionCounter />
            <AtollAttractionCounter />
            <AtollAttractionCounter />
            <AtollAttractionCounter />
          </div> */}
          <div className="atoll-gallery">
            <h3>Gallery</h3>
            <Gallery
              qty={4}
              wrapperStyle={"atoll-gallery-wrapper"}
              itemStyle={"atoll-image"}
              images={props.atoll.Images}
            />
          </div>
        </div>
        <div className="atoll-header-grid-col-2">
          <AtollFacts atoll={props.atoll} />
        </div>
      </div>
    </div>
  );
};

export default AtollHeader;
