import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllAtolls } from "../../Global/Api";
import Loading from "../../Global/Loading/Loading";
import "./DestinationsList.css";

const DestinationsList = () => {
  const [atolls, setAtolls] = useState();

  const fetchAtolls = async () => {
    try {
      const { data, status } = await getAllAtolls();
      if (status === 200) {
        console.log(data.data);
        setAtolls(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAtolls();
  }, []);

  if (atolls === undefined) return <Loading />;
  return (
    <div className="destinations-list">
      <h1 className="britania section-header">Map of Maldives</h1>
      <p>
        Utheemu Ganduvaru is the birthplace and home of Sultan Mohamed
        Thakurufaanu, one of the most revered heroes in Maldivian history.
        Utheemu Ganduvaru, which translates to Utheemu Palace is located in
        Utheemu in Haa Alifu Atoll, in the northernmost extremes of the
        Maldives. In 1558 the Portuguese invaded the Maldives killing the Sultan
        in battle and establishing their rule over Maldives. Mohamed
        Thakurufaanu, and his brothers Ali and Hassan left the Maldives to
        Minicoy to make preparations for their campaign to free the country from
        Portuguese rule. Kalhuohfummi, the boat they built for their campaign,
        played a crucial role in their efforts. The band of fighters led by the
        Mohamed Thakurufaanu and his brothers fought the Portuguese for eight
        years. In order to evade capture, they would land on different islands
        in the darkness of the night, fought the Portuguese and left before
        daybreak. Eventually they landed in Male’ and defeated the Portuguese
        leadership in the capital. Free from the shackles of the Portuguese, the
        people chose Mohamed Thukurufaanu as the Sultan.
      </p>
      <h2 className="section-header">All Islands & Atolls</h2>
      <div className="destinations-grid">
        {atolls !== "undefined" &&
          atolls.map((atoll, key) => {
            if (atoll.attributes.islands.data.length > 0)
              return (
                <div key={key}>
                  <Link to={"/atoll/" + atoll.attributes.AtollCode}>
                    {" "}
                    <h1 className="britania destinations-atollcode">
                      {atoll.attributes.AtollCode}
                    </h1>{" "}
                    <h2 className="britania destinations-atollname">
                      {atoll.attributes.Name}
                    </h2>{" "}
                  </Link>
                  {atoll.attributes.islands.data.map((island) => (
                    <li
                      key={island.id}
                      className={
                        island.attributes.properties.data.length > 0
                          ? "bold destinations-island"
                          : "destinations-island"
                      }
                    >
                      <Link
                        to={
                          "/island/" +
                          atoll.attributes.AtollCode +
                          "/" +
                          island.attributes.Name
                        }
                      >
                        {island.attributes.Name}
                      </Link>
                    </li>
                  ))}
                </div>
              );
            else
              return (
                <div key={key}>
                  <Link to={"/atoll/" + atoll.attributes.AtollCode}>
                    {" "}
                    <h1 className="britania destinations-atollcode">
                      {atoll.attributes.AtollCode}
                    </h1>{" "}
                    <h2 className="britania destinations-atollname">
                      {atoll.attributes.Name}
                    </h2>{" "}
                  </Link>
                </div>
              );
          })}
      </div>
    </div>
  );
};

export default DestinationsList;
