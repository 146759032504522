import React, { useState, useEffect } from "react";
// import { renderMatches } from "react-router-dom";
import styled from "styled-components";
import RightChevron from "../../Assets/SVG/RightChevron";

const DropDownContainer = styled("div")`
  display: inline-flex;
  width: 100%;
  flex-direction: column;
`;

const DropDownHeader = styled("div")`
  padding: 1em 1.5em 1em 1.5em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1rem;
  color: var(--white);
  background: var(--mvblue);
  border-radius: 15px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  width: auto;
  position: absolute;
  z-index: 3;
  background: var(--white);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  box-sizing: border-box;
  color: var(--darkblue);
  font-size: 1rem;
  font-weight: 500;
  max-height: 250px;
  overflow-y: auto;
`;

const ListItem = styled("li")`
  padding: 1rem 2rem;
  list-style: none;
  margin-bottom: 0.8em;
  cursor: pointer;
  &:hover,
  &:focus {
    background: var(--neonblue);
  }
`;

var optionVals = [];

export default function App(props) {
  const handleSelect = props.handleSelect;
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState();
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (id, code) => () => {
    // setSelectedOption(value);
    setIsOpen(false);
    handleSelect(id, code);
  };

//   useEffect(() => {
//     console.log(selectedOption);
//   }, [selectedOption]);

  useEffect(() => {
    optionVals = props.options;
    setOptions(optionVals);
    // console.log(optionVals);
  }, [props.options]);

  if (props.options === undefined || props.options === null) return null;
  return (
    <DropDownContainer>
      <DropDownHeader onClick={toggling}>
        {props.default}
        <RightChevron style={{ fill: " white", transform: "rotate(" + 90 + "deg)" }} />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options?.map((option) => (
              <ListItem
                onClick={onOptionClicked(option.id, option.code)}
                key={option.id}
              >
                {option.Name}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
}
