import React from "react";

const DashboardQuotationEdit = () => {
  return (
    <div className="capitalize">
      <h3>copy layout from property edit and add the following fields:</h3>
      <ul>
        <h4> booking details (non-editable and automatically filled when booking id is selected)</h4>
        <li> atoll, island and property </li>
        <li>checkin and check out dates.</li>
        <li>number of adult guests</li>
        <li>number of child guests</li>
        <li>budget</li>
        <li>special requirements</li>
        <li>status dropdown</li>
        <li>activities</li>
        <li>package</li>
        <h4>guest details (also from bookings)</h4>
        <li>guest name</li>
        <li>nationality</li>
        <li>passport number</li>
        <li>email id</li>
        <h4>Quote details</h4>
        <li>Room Qty</li>
        <li>Room Price per night</li>
        <li>Activities list probably as a textarea</li>
        <li>price for all activities in one field</li>
      </ul>
      <em>* Add fields as necessary</em>
    </div>
  );
};

export default DashboardQuotationEdit;
