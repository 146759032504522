import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import "./Dashboard.css";
import { managersProperties } from "../../components/Global/Api";
import Loading from "../../components/Global/Loading/Loading";
import { Line } from "react-chartjs-2";

const Dashboard = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
    Legend,
    Title
  );

  const [sales, setSales] = useState({});
  const [SalesCommissionTotal, setSalesCommissionTotal] = useState({});
  const [numberOfGuests, setNumberOfGuests] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalCommsEarnings, setTotalCommsEarnings] = useState(0);

  let dateCache = [];
  let commsDateCache = [];
  const createSalesDataset = (date, total, commsTotal) => {
    let month = new Date(date).toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    let months = Object.keys(dateCache);

    months.forEach((element) => {
      console.log(month + "" + element);
      if (element === month) {
        dateCache[element] = dateCache[element] + total;
        commsDateCache[element] = commsDateCache[element] + commsTotal;
        return;
      }
    });
    commsDateCache = { [month]: commsTotal, ...commsDateCache };
    dateCache = { [month]: total, ...dateCache };
    // });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "monthly sales",
      },
    },
  };

  // const data = {
  //   datasets: [
  //     {
  //       fill: false,
  //       label: "Dataset 2",
  //       // data: { data },
  //       borderColor: "rgb(53, 162, 235)",
  //       backgroundColor: "rgba(53, 162, 235, 0.5)",
  //     },
  //   ],
  // };

  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      try {
        const { data } = await managersProperties(
          sessionStorage.getItem("token")
        );

        for (let index = 0; index < data.properties.length; index++) {
          for (
            let bookingIndex = 0;
            bookingIndex < data.properties[index].bookings.length;
            bookingIndex++
          ) {
            if (
              data.properties[index].bookings[bookingIndex].status ===
              "completed"
            ) {
              for (
                let quotationIndex = 0;
                quotationIndex <
                data.properties[index].bookings[bookingIndex].quotations.length;
                quotationIndex++
              ) {
                if (
                  data.properties[index].bookings[bookingIndex].quotations[
                    quotationIndex
                  ].status === "accepted"
                ) {
                  createSalesDataset(
                    data.properties[index].bookings[bookingIndex].quotations[
                      quotationIndex
                    ].check_out,
                    data.properties[index].bookings[bookingIndex].quotations[
                      quotationIndex
                    ].Total,
                    data.properties[index].bookings[bookingIndex].quotations[
                      quotationIndex
                    ].Total_After_Commission
                  );
                  setNumberOfGuests(
                    (sum) =>
                      sum +
                      data.properties[index].bookings[bookingIndex]
                        .number_of_guests
                  );
                }
              }
            }
          }
        }
        let sortedTotal = {};
        let sortedCommsTotal = {};

        let keys = Object.keys(dateCache).sort(
          (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );
        for (let k of keys) {
          sortedTotal[k] = dateCache[k];
          sortedCommsTotal[k] = commsDateCache[k];
        }

        setSalesCommissionTotal(sortedCommsTotal);
        setSales(sortedTotal);
        setTotalEarnings(() =>
          Object.values(dateCache).reduce((a, b) => a + b)
        );
        setTotalCommsEarnings(() =>
          Object.values(commsDateCache).reduce((a, b) => a + b)
        );

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
    // eslint-disable-next-line
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="dashboard-content">
      <div className="dashboard-content row">
        <div className="dashboard-figure">
          <span className="dashboard-figure-title">Number of Guests</span>
          <span className="dashboard-figure-amount">{numberOfGuests}</span>
        </div>

        <div className="dashboard-figure">
          <span className="dashboard-figure-title">Total Sales</span>
          <span className="dashboard-figure-amount">{totalEarnings}</span>
        </div>

        <div className="dashboard-figure">
          <span className="dashboard-figure-title">
            Total Commission earned
          </span>
          <span className="dashboard-figure-amount">{totalCommsEarnings}</span>
        </div>
      </div>

      <div className="chart">
        <Line
          options={options}
          data={{
            datasets: [
              {
                fill: true,
                label: "Total Sales Per Month",
                data: sales,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
            ],
          }}
        ></Line>
      </div>
      <div className="chart">
        <Line
          options={options}
          data={{
            datasets: [
              {
                fill: true,
                label: "Total Commission Per Month",
                data: SalesCommissionTotal,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
            ],
          }}
        ></Line>
      </div>
    </div>
  );
};

export default Dashboard;
