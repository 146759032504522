import React from "react";
import "./QuickBooking.css";
import BookingTutorial from "./BookingTutorial";
import QuickBookingForm from "./QuickBookingForm";

const QuickBooking = () => {
  return (
    <div className="quick-booking">
      <BookingTutorial />
      <QuickBookingForm/>
    </div>
  );
};

export default QuickBooking;
