import React, { useState, useEffect } from "react";
import placeholder from "../../../Assets/Images/mvstayplaceholder.jpg";
import "./Gallery.css";
import Fancybox from "../Fancybox";

const Gallery = (props) => {
  const [images, setImages] = useState();
  const [imageQTY, setImageQTY] = useState();

  useEffect(() => {
    console.log(props.images);
    setImages(props.images);
  }, [props.images]);

    useEffect(() => {
      setImageQTY(props.qty);
  }, [props.qty]);


  

  if (props.images === undefined) return null;
  return (
    <div className="MapDetailsGallery">
      {typeof images === "undefined" || images.data === null ? (
        <div className={props.wrapperStyle}>
          {[...Array(imageQTY)].map((e, i) => (
            <div
              key={i}
              className={props.itemStyle}
              style={{
                backgroundImage: "url(" + placeholder + ")",
              }}
            ></div>
          ))}
        </div>
      ) : (
        <Fancybox options={{ infinite: true }}>
          <div className={props.wrapperStyle}>
            {images.data.slice(0, imageQTY).map((data, key) => (
              <div
                key={data.id}
                id={key}
                data-fancybox="gallery"
                data-src={data.attributes.url}
                data-thumb={data.attributes.formats.thumbnail.url}
                data-caption={data.attributes.caption}
                className={props.itemStyle}
                style={{
                  backgroundImage:
                    "url(" + data.attributes.formats.small.url + ")",
                }}
              ></div>
            ))}
            {images.data.slice(imageQTY, images.length).map((data, key) => (
              <div
                key={data.id}
                id={key}
                data-fancybox="gallery"
                data-src={data.attributes.url}
                data-thumb={data.attributes.formats.thumbnail.url}
                className={props.itemStyle}
                style={{
                  display: "none",
                }}
              ></div>
            ))}
          </div>
        </Fancybox>
      )}
    </div>
  );
};

export default Gallery;
