import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBars,
  faCalendarCheck,
  faCommentDollar,
  faDisplay,
  faHouseCircleCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Outlet } from "react-router";
import "./DashboardLayout.css";
import logo from "../../../Assets/Images/logo_white.png";
import { Link, Navigate, NavLink } from "react-router-dom";

const DashboardLayout = () => {
  if (sessionStorage.getItem("token") === null) return <Navigate to="/login" />;
  const logout = () => {
    sessionStorage.removeItem("token");
  };
  return (
    <div className="menu-wrapper ">
      <div className="sidebar-header">
        <div className="sideBar dashboard-sidebar">
          <span
            className="cross-icon"
            onClick={() => {
              let sideBar = document.getElementsByClassName("sideBar")[0];
              let backDrop = document.getElementsByClassName("backdrop")[0];
              sideBar.classList.remove("showMenu");
              backDrop.classList.remove("showBackdrop");
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <img className="logo" alt="MVSTAY" src={logo} />
          <div className="dashboard-menu">
            <div
              className="dashboard-menu-item"
              onClick={() => {
                let sideBar = document.getElementsByClassName("sideBar")[0];
                let backDrop = document.getElementsByClassName("backdrop")[0];
                sideBar.classList.remove("showMenu");
                backDrop.classList.remove("showBackdrop");
              }}
            >
              <NavLink
                className={(navData) =>
                  navData.isActive ? "active  menu-item" : "menu-item"
                }
                to="/dashboard"
              >
                <div className="menu-icon-wrapper">
                  <FontAwesomeIcon icon={faDisplay} />
                </div>
                <label className="menu-item">Dashboard</label>
              </NavLink>
            </div>

            <div
              className="dashboard-menu-item"
              onClick={() => {
                let sideBar = document.getElementsByClassName("sideBar")[0];
                let backDrop = document.getElementsByClassName("backdrop")[0];
                sideBar.classList.remove("showMenu");
                backDrop.classList.remove("showBackdrop");
              }}
            >
              <NavLink
                className={(navData) =>
                  navData.isActive ? "active  menu-item" : "menu-item"
                }
                to="/dashboard/properties"
              >
                <div className="menu-icon-wrapper">
                  <FontAwesomeIcon icon={faHouseCircleCheck} />
                </div>
                <label className="menu-item">Properties</label>
              </NavLink>
            </div>

            <div
              className="dashboard-menu-item"
              onClick={() => {
                let sideBar = document.getElementsByClassName("sideBar")[0];
                let backDrop = document.getElementsByClassName("backdrop")[0];
                sideBar.classList.remove("showMenu");
                backDrop.classList.remove("showBackdrop");
              }}
            >
              <NavLink
                className={(navData) =>
                  navData.isActive ? "active  menu-item" : "menu-item"
                }
                to="/dashboard/bookings"
              >
                <div className="menu-icon-wrapper">
                  <FontAwesomeIcon icon={faCalendarCheck} />
                </div>
                <label className="menu-item">Bookings</label>
              </NavLink>
            </div>

            <div
              className="dashboard-menu-item"
              onClick={() => {
                let sideBar = document.getElementsByClassName("sideBar")[0];
                let backDrop = document.getElementsByClassName("backdrop")[0];
                sideBar.classList.remove("showMenu");
                backDrop.classList.remove("showBackdrop");
              }}
            >
              <NavLink
                className={(navData) =>
                  navData.isActive ? "active  menu-item" : "menu-item"
                }
                to="/dashboard/quotes"
              >
                <div className="menu-icon-wrapper">
                  <FontAwesomeIcon icon={faCommentDollar} />
                </div>
                <label className="menu-item">Quotations</label>
              </NavLink>
            </div>
          </div>
        </div>
        <div
          className="backdrop"
          onClick={() => {
            let sideBar = document.getElementsByClassName("sideBar")[0];
            let backDrop = document.getElementsByClassName("backdrop")[0];
            sideBar.classList.remove("showMenu");
            backDrop.classList.remove("showBackdrop");
          }}
        ></div>
        <div className="content">
          <header>
            <div
              className="menu-button"
              id="desktop"
              onClick={() => {
                const menuItems = Array.from(
                  document.getElementsByClassName("menu-item")
                );
                menuItems.forEach((menuItem) => {
                  menuItem.classList.toggle("hideMenuList");
                });
                let sideBar = document.getElementsByClassName("sideBar")[0];
                sideBar.classList.toggle("widthChange");
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
            <div
              className="menu-button"
              id="mobile"
              onClick={() => {
                let sideBar = document.getElementsByClassName("sideBar")[0];
                let backDrop = document.getElementsByClassName("backdrop")[0];
                sideBar.classList.add("showMenu");
                sideBar.classList.remove("widthChange");
                backDrop.classList.add("showBackdrop");
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>

            <Link onClick={logout} className="logout-button" to={"/login"}>
              <span>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </span>
              <span> Logout</span>
            </Link>
          </header>
          <div className="content-data">
            <Outlet />
          </div>
        </div>
      </div>
      {/* <div className="dashboard">
        <DashboardSidebar />

        <div className="dashboard-content-area">
          <div className="dashboard-top-bar">
            <Link onClick={logout} to={"/login"}>
              <span className="logout-button">
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </span>
              <span>Logout</span>
            </Link>
          </div>
          <Outlet />
          <div className="bottom-border"></div>
        </div>
      </div> */}
      ;
    </div>
  );
};
export default DashboardLayout;
