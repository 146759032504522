import React, { useEffect, useState } from "react";
import ActivityGrid from "../../components/ActivityComponents/ActivityGrid";
import ActivityHero from "../../components/ActivityComponents/ActivityHero";
import PopularActivities from "../../components/ActivityComponents/PopularActivities";
import {
  getActivityHomepage,
  getAllActivities,
} from "../../components/Global/Api";
import { useNavigate } from "react-router-dom";

import "./Activity.css";

const Activity = () => {
  const [activities, setActivities] = useState(0);
  // const [specialActivities, setSpecialActivities] = useState(0);
  const [homePage, setHomePage] = useState(0);
  let navigate = useNavigate();

  const fetchActivities = async () => {
    try {
      const { data, status } = await getAllActivities();
      if (status === 200) {
        // console.log(data);
        setActivities(data);
      }
    } catch (error) {
      console.log(error);
      navigate("../404");
    }
  };
  const fetchHomePage = async () => {
    try {
      const { data, status } = await getActivityHomepage();
      if (status === 200) {
        // console.log(data);
        setHomePage(data.data.attributes);
      }
    } catch (error) {
      console.log(error);
      navigate("../404");
    }
  };
  // const fetchSpecialActivities = async () => {
  //   try {
  //     const { data, status } = await getAllSpecialActivities();
  //     if (status === 200) {
  //       // console.log(data);
  //       setSpecialActivities(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     navigate("../404");
  //   }
  // };

  useEffect(() => {
    fetchActivities();
    fetchHomePage();
    // fetchSpecialActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ActivityHero />
      <ActivityGrid
        heading={homePage.Heading}
        activities={activities}
        paragraph={homePage.paragraph}
      />
      <PopularActivities
        activities={activities}
        // specialActivities={specialActivities}
        paragraph={homePage.PopularActivitiesParagraph}
      />
    </div>
  );
};

export default Activity;
