import React from "react";
import "./PropertyRelatedGuesthouses.css";
import PropertyRelatedGuesthousesSlider from "./PropertyRelatedGuesthousesSlider";

const PropertyRelatedGuesthouses = (props) => {
  if (props.property === undefined) return null;
  return (
    <div className="property-related-guesthouses">
      <div className="separator">
        MORE GUESTHOUSES IN{" "}
        {props.property && props.property.island.data.attributes.Name}
      </div>
      <PropertyRelatedGuesthousesSlider />
    </div>
  );
};

export default PropertyRelatedGuesthouses;
