import * as React from "react"

const MakeBooking = ({ title, titleId, ...props }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57.726 88.438"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
            d="M10.31 19.277c0-.708-.008-1.352.002-1.996a2.062 2.062 0 1 1 4.119-.002c.01.644.002 1.289.002 1.965H35.05c0-.61-.003-1.229.001-1.847a2.073 2.073 0 1 1 4.122.025c.004.584 0 1.168 0 1.855.248 0 .473-.004.698 0a39.02 39.02 0 0 1 4.433.13 6.067 6.067 0 0 1 5.168 5.98c.037 2.382.01 4.765.01 7.147q.002 9.226 0 18.451v.81c-.251-.21-.413-.335-.565-.471q-4.433-3.966-8.863-7.937a4.058 4.058 0 0 0-4.34-.977 4.104 4.104 0 0 0-2.724 4.007q-.007 8.868-.002 17.737v.867H6.512a6.234 6.234 0 0 1-6.51-6.576q-.004-16.307 0-32.614a6.234 6.234 0 0 1 6.53-6.554h3.778ZM4.137 31.704h41.22c0-2.124.023-4.2-.009-6.277a2.03 2.03 0 0 0-2.058-1.987c-.923-.018-1.846-.004-2.769-.004h-1.346c0 .703.004 1.308-.001 1.913a2.07 2.07 0 1 1-4.122-.029c-.004-.618 0-1.236 0-1.833H14.433v1.736a2.08 2.08 0 1 1-4.123.028c-.002-.596 0-1.192 0-1.81-1.49 0-2.884-.03-4.277.01a1.935 1.935 0 0 0-1.88 1.81c-.055 2.138-.017 4.277-.017 6.443Zm9.259 10.448c-.365 0-.73-.015-1.095.002a2.074 2.074 0 0 0-.02 4.144c.75.032 1.503.033 2.253 0a2.074 2.074 0 0 0-.044-4.144c-.364-.016-.73-.003-1.094-.002Zm12.402 4.152v-.002c.386 0 .774.024 1.159-.005a2.074 2.074 0 0 0-.02-4.136 24.17 24.17 0 0 0-2.317 0 2.051 2.051 0 0 0-1.937 2.043 2.079 2.079 0 0 0 1.956 2.096c.385.023.773.004 1.16.004Zm-12.353 4.165c-.387 0-.774-.016-1.16.003a2.074 2.074 0 0 0 .012 4.144c.75.03 1.503.034 2.253-.002a2.074 2.074 0 0 0-.01-4.14c-.364-.022-.73-.004-1.095-.005Zm12.418.004c-.429 0-.86-.026-1.287.005a2.073 2.073 0 0 0-.02 4.128 19.11 19.11 0 0 0 2.445 0 2.052 2.052 0 0 0 1.862-2.115 2.079 2.079 0 0 0-1.905-2.015c-.363-.026-.73-.004-1.095-.003Z"
    />
    <path
           d="M35.051 58.784c0-4.05.01-8.1-.006-12.15a2.167 2.167 0 0 1 1.247-2.218 2.075 2.075 0 0 1 2.41.531q9.02 8.103 18.058 16.183a2.228 2.228 0 0 1 .88 2.397 2.202 2.202 0 0 1-1.384 1.43q-8.35 3.731-16.695 7.477c-.51.228-1.016.464-1.53.68a2.05 2.05 0 0 1-2.975-1.984c-.012-4.116-.004-8.23-.005-12.346Z"
    />
  </svg>
)

export default MakeBooking
