import React, { useEffect, useState } from "react";
import "./AtollFeaturedIslandSlide.css";
import AtollInfoWidget from "../AtollFacts/AtollInfoWidget";
import { Link } from "react-router-dom";
import RightChevron from "../../../Assets/SVG/RightChevron";
import Placeholder from "../../../Assets/Images/mvstayplaceholder.jpg";
const AtollFeaturedIslandSlide = (props) => {
  const [islandSlide, setIslandSlide] = useState();
  useEffect (() => {
    setIslandSlide(props.island);

  }, [props.island]);

  if (islandSlide === undefined) return null;
  return (
    <div>
      <div className="featured-island-slide">
      {(islandSlide.Featured_Image.data !==
              null) ? (
                <div
                className="featured-island-image"
                style={{
                  backgroundImage:
                    "url(" + islandSlide.Featured_Image.data.attributes.url + ")",
                }}
              >
                <div className="featured-island-name">{ props.atoll.AtollCode + ". " + islandSlide.Name}</div>
                <AtollInfoWidget className="slider-info-widget" />
                <Link className="WhiteButton" to={"/island/" + props.atoll.AtollCode + "/" + islandSlide.Name}>
                  Take Me There <RightChevron />{" "}
                </Link>
              </div>
            ) : (
              <div
              className="featured-island-image"
              style={{
                backgroundImage:
                  "url(" + Placeholder + ")",
              }}
            >
              <div className="featured-island-name">{ props.atoll.AtollCode + ". " + islandSlide.Name}</div>
              <AtollInfoWidget className="slider-info-widget" />
              <Link className="WhiteButton" to={"/island/" + props.atoll.AtollCode + "/" + islandSlide.Name}>
                Take Me There <RightChevron />{" "}
              </Link>
            </div>
            )}
      </div>
    </div>
  );
};

export default AtollFeaturedIslandSlide;
