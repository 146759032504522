import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { makeBooking } from "../../components/Global/Api";
import bg from "../../Assets/Images/booking.jpg";
import logo from "../../Assets/Images/logo_white.png";
import "./Booking.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAlt,
  faDollarSign,
  faEnvelope,
  faGlobeEurope,
  faLeftLong,
  faLocationDot,
  faPassport,
  faPeopleRoof,
  faPlaneArrival,
  faPlaneDeparture,
  faSmileBeam,
  faUserAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import ms from "ms";
// import moment from "moment";

const Booking = (props) => {
  const bookingData = useLocation();
  let navigate = useNavigate();
  const [failed, setFailed] = useState(false);
  const [island] = useState(bookingData.state?.island || "");
  const [atoll] = useState(bookingData.state?.atoll || "");
  const [property, setProperty] = useState(bookingData.state?.property || "");
  const [packageDeals, setPackageDeals] = useState(
    bookingData.state?.packageDeals || ""
  );
  const [checkIn, setCheckIn] = useState(bookingData.state?.checkIn || "");

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    const mincheckin = ms("14d");
    const mincheckout = ms("1d");
    const min_date = new Date(+new Date(checkIn) + mincheckout);
    const max_date = new Date(+new Date() + mincheckin);
    // setMinDate(moment(min_date).format("YYYY-MM-DD"));
    setMinDate(min_date);
    // setMaxDate(moment(max_date).format("YYYY-MM-DD"));
    setMaxDate(max_date);
  }, [checkIn]);

  const [checkOut, setCheckOut] = useState(bookingData.state?.checkOut || "");
  const [guestQty, setGuestQty] = useState(
    bookingData.state?.numberOfGuests || ""
  );
  const [budget, setBudget] = useState(bookingData.state?.budget || "");
  const [activities, setActivities] = useState(
    bookingData.state?.activities?.toString() || ""
  );
  const [requests, setRequests] = useState(
    bookingData.state?.specialRequest || ""
  );
  const [guestName, setGuestName] = useState("");
  const [country, setCountry] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [email, setEmail] = useState("");
  const [booking, setBooking] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    setBooking({
      // location: location,
      // accommodation: accommodation,
      check_in: checkIn,
      check_out: checkOut,
      number_of_guests: guestQty,
      budget: budget,
      activities: activities,
      special_requirements: requests,
      Guest_Name: guestName,
      country: country,
      passportNo: passportNo,
      email: email,
    });
    if (atoll !== "") {
      console.log(atoll.id);
      setBooking((prevState) => ({
        ...prevState,
        atoll: atoll.id,
      }));
    }
    if (island !== "") {
      setBooking((prevState) => ({
        ...prevState,
        island: island.id,
      }));
    }
    if (property !== "") {
      setBooking((prevState) => ({
        ...prevState,
        property: property.id,
      }));
    }
    if (packageDeals !== "") {
      setBooking((prevState) => ({
        ...prevState,
        package: packageDeals.id,
      }));
    }
  };
  const sendBooking = async () => {
    if (booking !== "") {
      try {
        let res = await makeBooking(booking);
        console.log(res);
        navigate("/thankyou");
      } catch (error) {
        setFailed(true);
      }
    }
  };
  useEffect(() => {
    sendBooking();
    console.log("New Booking:", booking);
    // eslint-disable-next-line
  }, [booking]);

  useEffect(() => {
    if (!bookingData.state) navigate("/404");
    console.log(bookingData.state);
    // eslint-disable-next-line
  }, [bookingData]);

  return (
    <div
      className="booking-page"
      style={{
        backgroundImage: "url(" + bg + ")",
      }}
    >
      <div className="booking-box">
        <img className="logo" alt="MVSTAY" src={logo} />
        <h1 className="britania">Confirm Your Booking</h1>
        <form onSubmit={handleSubmit}>
          <div className="booking-form">
            <div>
              <label className="booking-label" htmlFor="location">
                <span className="readonly">
                  <FontAwesomeIcon icon={faLocationDot} />
                </span>
                {" Destination"}
              </label>
              <div className="readonly">
                <textarea
                  name="location"
                  id="location"
                  readOnly
                  placeholder="Location"
                  value={
                    atoll || island
                      ? (atoll && atoll.attributes.Name + " Atoll ") +
                        (island && island.attributes.Name)
                      : "Atoll or Island not selected. We will suggest a location based on your requirements."
                  }
                  className="field"
                ></textarea>
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="accommodation">
                <span className="readonly">
                  <FontAwesomeIcon icon={faPeopleRoof} />
                </span>
                {" Accommodation"}
              </label>
              <div className="field-wrap readonly">
                <textarea
                  type="text"
                  name="accommodation"
                  id="accommodation"
                  placeholder="Accomodation"
                  readOnly
                  onChange={(event) => setProperty(event.target.value)}
                  value={
                    property
                      ? property.attributes.Name
                      : "Accommodation not selected. We will suggest an accommodation based on your requirements."
                  }
                  className="field"
                ></textarea>
              </div>
            </div>
            {packageDeals ? (
              <div>
                <label className="booking-label" htmlFor="package">
                  <span className="readonly">
                    <FontAwesomeIcon icon={faPeopleRoof} />
                  </span>
                  {" Package"}
                </label>
                <div className="field-wrap readonly">
                  <textarea
                    type="text"
                    name="package"
                    id="package"
                    placeholder="package"
                    readOnly
                    onChange={(event) => setPackageDeals(event.target.value)}
                    value={packageDeals.attributes.Name}
                    className="field"
                  ></textarea>
                </div>
              </div>
            ) : null}

            <div>
              <label className="booking-label" htmlFor="guestQty">
                No. of Guests<span className="required">*</span>
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faUsers} />
                </span>
                <input
                  type="number"
                  name="guestQty"
                  id="guestQty"
                  min={1}
                  max={20}
                  required
                  onChange={(event) => setGuestQty(event.target.value)}
                  value={guestQty}
                  className="field"
                ></input>
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="guestQty">
                Budget<span className="required">*</span>
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faDollarSign} />
                </span>
                <input
                  type="number"
                  name="budget"
                  id="budget"
                  step={500}
                  required
                  onChange={(event) => setBudget(event.target.value)}
                  value={budget}
                  className="field"
                ></input>
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="checkIn">
                Check-in<span className="required">*</span>
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faPlaneArrival} />
                </span>
                <DatePicker
                  selected={checkIn}
                  onChange={(event) => setCheckIn(event)}
                  minDate={maxDate}
                  placeholderText="click to slect a date"
                  required
                />
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="checkOut">
                Check-out<span className="required">*</span>
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faPlaneDeparture} />
                </span>
                <DatePicker
                  selected={checkOut}
                  onChange={(event) => setCheckOut(event)}
                  minDate={minDate}
                  placeholderText="click to select a date"
                  required
                />
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="activities">
                Activities
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faSmileBeam} />
                </span>
                <textarea
                  type="number"
                  name="activities"
                  id="activities"
                  placeholder="List of Activities"
                  onChange={(event) => setActivities(event.target.value)}
                  value={activities}
                  className="field"
                ></textarea>
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="activities">
                Special Requests
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faCommentAlt} />
                </span>
                <textarea
                  type="number"
                  name="requests"
                  id="requests"
                  placeholder="Special Requests"
                  onChange={(event) => setRequests(event.target.value)}
                  value={requests}
                  className="field"
                ></textarea>
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="guestName">
                Name<span className="required">*</span>
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faUserAlt} />
                </span>
                <input
                  type="text"
                  name="guestName"
                  id="guestName"
                  required
                  placeholder="Your Full Name"
                  onChange={(event) => setGuestName(event.target.value)}
                  value={guestName}
                  className="field"
                ></input>
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="country">
                Country<span className="required">*</span>
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faGlobeEurope} />
                </span>
                <input
                  type="text"
                  name="country"
                  id="country"
                  required
                  placeholder="Your Country of Origin"
                  onChange={(event) => setCountry(event.target.value)}
                  value={country}
                  className="field"
                ></input>
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="passportNo">
                Passport No.<span className="required">*</span>
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faPassport} />
                </span>
                <input
                  type="text"
                  name="passportNo"
                  id="passportNo"
                  required
                  placeholder="Your Passport Number"
                  onChange={(event) => setPassportNo(event.target.value)}
                  value={passportNo}
                  className="field"
                ></input>
              </div>
            </div>

            <div>
              <label className="booking-label" htmlFor="email">
                Email<span className="required">*</span>
              </label>
              <div className="field-wrap">
                <span className="field-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  placeholder="Your Email Address"
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                  className="field"
                ></input>
              </div>
            </div>

            <div>
              <input
                type="submit"
                name="booking"
                className="BlueButton"
              ></input>
              {failed && (
                <p className="failed-submission">
                  Submission failed please try again
                </p>
              )}
              <p className="submit-instruction">
                Get a personalized quote sent to your e-mail within 4 working
                days.
              </p>
            </div>
            <div className="note">
              <h4>Note:</h4>
              <ol>
                <li>
                  We take reasonable care in providing our Platform, but we
                  can’t guarantee that everything on it is accurate (we get
                  information from the Service Providers). To the extent
                  permitted by law, we can’t be held responsible for any errors,
                  any interruptions, or any missing bits of information -
                  although we will do everything we can to correct/fix them as
                  soon as we can.
                </li>

                <li>
                  Our Platform is not a recommendation or endorsement of any
                  Service Provider or its products, services, facilities,
                  vehicles, etc.
                </li>
                <li>
                  We’re not a party to the terms between you and the Service
                  Provider. The Service Provider is solely responsible for the
                  Travel Experience.
                </li>
              </ol>
              <Link className="back-link" to={"/"}>
                <FontAwesomeIcon icon={faLeftLong} /> Back to Homepage
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Booking;
