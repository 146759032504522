import React from 'react';
import './FeaturedSlides.css';
const FeaturedSlides = () => {
  return (
    <div>
    <div className="featuredSlider_container__image-container">
      <div className="gradient">
        <img
          className="featuredSlider_container__image"
          alt=""
          src="https://wordpress-705520-2539783.cloudwaysapps.com/wp-content/uploads/2022/03/Asset-1-300x205.png"
        />
        
      </div>
    </div>
    <div className="featuredSlider_description-wrapper">
      <div className="featuredSlider_description-container">
        <h2>Ocean Retreat Inn K.Thulusdhoo</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
          diam nonummy nibh euismod tincidunt ut laoreet dolore magna
          aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
          nostrud exerci laoreet dolore magna aliquam erat volutpat. Ut
          wisi enim ad minim veniam, quis nostrud exerci laoreet dolore
          magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
          nostrud exerci
        </p>
      </div>
    </div>
    <div className="featuredSlider_button-wrapper">
      <div className="featuredSlider_button-container">
        <h2>See Hot Deals</h2>
        
      </div>
    </div>
  </div>
  )
}

export default FeaturedSlides
