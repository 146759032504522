import React, { useState, useEffect } from "react";
import "./FamousFor.css";

const FamousFor = (props) => {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    setTags(props.tags);
    }, [props.tags]);

  if (props.tags === undefined) return null;
  return (
    <div className="FamousFor_Container">
      {tags.map((data, key) => <span key={key}>{data.famous_for}</span>)}
    </div>
  );
};

export default FamousFor;
