import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="fof-container">
      <h1>Whoops! Looks like this page went on vacation!</h1>
      <h3>404 PAGE NOT FOUND!</h3>
      <img
        width={500}
        alt="404 error"
        src="https://mvstay-bucket.s3.ap-south-1.amazonaws.com/portrait_beautiful_young_asian_woman_relax_smile_leisure_around_beach_sea_ocean_travel_vacation_trip_3bf3188728.jpg?updated_at=2022-07-23T23:51:49.527Z"
      />
      <Link to={"./"}>Take Me Back Home.</Link>
    </div>
  );
};

export default NotFound;
