import React, { useEffect, useState } from "react";
import "./PropertyBooking.css";
import { Link } from "react-router-dom";
import ms from "ms";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PropertyBooking = (props) => {
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    const mincheckin = ms("14d");
    const mincheckout = ms("1d");
    const min_date = new Date(+new Date(checkIn) + mincheckout);
    const max_date = new Date(+new Date() + mincheckin);
    setMinDate(min_date);
    setMaxDate(max_date);
  }, [checkIn]);
  const [numberOfGuests, setNumberOfGuests] = useState(null);
  if (props.property.island === undefined) return null;
  return (
    <div className="property-booking">
      <h2 className="britania">Confirm your trip</h2>

      <div className="confirm-your-trip">
        <div className="confirm-your-trip-field">
          <h4>Destination</h4>
          <p>
            {
              props.property.island.data.attributes.atoll.data.attributes
                .AtollCode
            }
            . {props.property.island.data.attributes.Name}
          </p>
        </div>

        <div className="confirm-your-trip-field">
          <label htmlFor="guest-count">
            <h4>No. of Guests</h4>
          </label>
          <input
            type="number"
            id="guest-count"
            name="guestcount"
            min={1}
            max={20}
            onChange={(e) => setNumberOfGuests(e.target.value)}
            required
          ></input>
          {/* <p>{numberOfGuests}</p> */}
        </div>

        <div className="confirm-your-trip-field">
          <h4>Activities</h4>
          <h5>You can choose activities above.</h5>
          {props.activities.map((activity, key) => (
            <p key={key}>{activity}</p>
          ))}
        </div>

        <div className="confirm-your-trip-field">
          <label htmlFor="checkin-date">
            <h4>Check-In</h4>
            {/* <p>{checkIn.toString()}</p> */}
          </label>
          <DatePicker
            selected={checkIn}
            onChange={(event) => setCheckIn(event)}
            minDate={maxDate}
            placeholderText="click to select a date"
            required
          />
        </div>

        <div className="confirm-your-trip-field">
          <h4>Accommodation</h4>
          <p>{props.property.Name}</p>
        </div>

        <div className="confirm-your-trip-field">
          <label htmlFor="checkout-date">
            <h4>Check-Out</h4>
          </label>
          {/* <p>{checkOut.toString()}</p> */}
          <DatePicker
            selected={checkOut}
            onChange={(event) => setCheckOut(event)}
            minDate={minDate}
            placeholderText="click to select a date"
            required
          />
        </div>
      </div>
      <Link
        to="/booking"
        state={{
          activities: props.activities,
          numberOfGuests: numberOfGuests,
          checkOut: checkOut,
          checkIn: checkIn,
          property: { id: props.propertyID, attributes: props.property },
          island: props.property.island.data,
          atoll: props.property.island.data.attributes.atoll.data,
        }}
      >
        <div className="BlueButton">Click to confirm</div>
      </Link>
    </div>
  );
};

export default PropertyBooking;
