import "./Loading.css";
import Lottie from "react-lottie";
import * as loadingAnimation from "./Loading.json";

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="loading-wrapper">
      <Lottie options={defaultOptions} height={100} width={100} />
      {/* <img alt="loading" src={loadinggif} /> */}
    </div>
  );
};

export default Loading;
