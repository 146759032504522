import React from "react";
import "./Tutorial.css";
import MakeBooking from "../../../Assets/SVG/MakeBooking";
import ChooseAtoll from "../../../Assets/SVG/ChooseAtoll";
import ChooseIsland from "../../../Assets/SVG/ChooseIsland";

const Tutorial = () => {
  return (
    <div className="tutorial_Grid_Container">
      <div className="tutorial_Header_Wrapper">
        <h1>Choose Your Destination</h1>
      </div>
      <div className="tutorial_Icon_Wrapper">
        <div className="tutorial_Icon_Box">
          <ChooseAtoll className="tutorial_Icon" />{" "}
        </div>
        <h3>Choose Atoll</h3>
        <div className="tutorial_Icon_Box">
          <ChooseIsland className="tutorial_Icon" />
        </div>
        <h3>Choose Island</h3>
        <div className="tutorial_Icon_Box">
          <MakeBooking className="tutorial_Icon" />
        </div>
        <h3>Make Booking</h3>
      </div>
    </div>
  );
};

export default Tutorial;
