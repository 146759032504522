import React, { useEffect, useState, useRef } from "react";
import "./DashboardPropertyEdit.css";
import {
  getPropertyById,
  managersPropertiesChange,
  imageUpload,
  deleteImage,
} from "../../Global/Api";
import image from "../../../Assets/Images/mvstayplaceholder.jpg";
import Loading from "../../Global/Loading/Loading";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
const DashboardPropertyEdit = () => {
  const { propertyid } = useParams();
  const [property, setProperty] = useState(0);
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [propertyType, setPropertyType] = useState("");
  let featuredImageRef = React.createRef();
  let imagesRef = React.createRef();
  let imagesDeletionRef = useRef([]);
  const fetchProperties = async () => {
    try {
      const { data } = await getPropertyById(propertyid);
      console.log(data.data);
      setProperty(data.data);
      setName(data.data.attributes.Name);
      setDescription(data.data.attributes.Description);
      setPropertyType(data.data.attributes.Accomodation_Type);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDetailsSubmit = async (event) => {
    event.preventDefault();
    let data = {
      Name: name,
      Description: description,
      Accomodation_Type: propertyType,
    };
    const res = await managersPropertiesChange(
      sessionStorage.getItem("token"),
      propertyid,
      data
    );
    console.log(res);
  };
  const handleFeaturedImageSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("files", featuredImageRef.current.files[0]);
    formData.append("ref", "api::property.property");
    formData.append("refId", propertyid);
    formData.append("field", "Featured_Image");
    await deleteImage(
      sessionStorage.getItem("token"),
      property.attributes.Featured_Image.data.id
    );
    const res = await imageUpload(
      sessionStorage.getItem("token"),
      propertyid,
      formData
    );
    console.log(res);
    fetchProperties();
  };

  const handleImagesSubmit = async (event) => {
    event.preventDefault();
    let res = null;
    for (let index = 0; index < imagesRef.current.files.length; index++) {
      const formData = new FormData();

      formData.append("files", imagesRef.current.files[index]);
      formData.append("ref", "api::property.property");
      formData.append("refId", propertyid);
      formData.append("field", "Images");
      res = await imageUpload(
        sessionStorage.getItem("token"),
        propertyid,
        formData
      );
    }
    console.log(res);
    fetchProperties();
  };
  const handleImagesDeletionSubmit = async (event) => {
    let res = null;
    event.preventDefault();
    for (let index = 0; index < imagesDeletionRef.current.length; index++) {
      if (imagesDeletionRef.current[index].checked === true) {
        res = await deleteImage(
          sessionStorage.getItem("token"),
          imagesDeletionRef.current[index].id
        );
        fetchProperties();
      }
    }
    console.log(res);
    window.location.reload(false);
  };

  useEffect(() => {
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;
  return (
    <div className="dashboard-properties-wrapper">
      <Link className="dashboard-card-button" to={"/dashboard/properties"}>
        <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Properties Page{" "}
      </Link>
      <h1 className="dashboard-properties-title">Edit {name}</h1>
      <h2 className="dashboard-properties-subtitle">{propertyType}</h2>
      <div className="dashboard-properties">
        <div className="dashboard-grid form">
          <div className="dashboard-form">
            <form onSubmit={handleDetailsSubmit}>
              <div className="dashboard-form-col-1">
                <div className="full-width">
                  <label htmlFor="propedname">property:</label>
                  <input
                    className="full-width"
                    id="propedname"
                    type="text"
                    defaultValue={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div className="full-width">
                  <label htmlFor="propedtype">Property Type:</label>
                  <select
                    className="full-width"
                    id="propedtype"
                    name="propedtype"
                    defaultValue={propertyType}
                    onChange={(event) => setPropertyType(event.target.value)}
                  >
                    <option value="Guesthouse">GuestHouse</option>
                    <option value="Homestay">Homestay</option>
                  </select>
                </div>
                <div className="full-width">
                  <label htmlFor="propeddesc">Description:</label>
                  <textarea
                    className="full-width"
                    id="propeddesc"
                    defaultValue={description}
                    onChange={(event) => setDescription(event.target.value)}
                  ></textarea>
                </div>

                <input
                  className="dashboard-button-green"
                  type="submit"
                  value="Save Property Details"
                ></input>
              </div>
            </form>
            <div className="dashboard-form-col-1">
              <form
                className="dashboard-form"
                onSubmit={handleFeaturedImageSubmit}
              >
                <div>
                  <label htmlFor="propedfimg">
                    Featured Image:{" "}
                    <img
                      width="200px"
                      alt=""
                      src={
                        property.attributes.Featured_Image.data === null
                          ? image
                          : property.attributes.Featured_Image.data.attributes
                              .url
                      }
                    />
                  </label>
                  <input
                    type="file"
                    id="propedfimg"
                    name="filename"
                    ref={featuredImageRef}
                  />
                </div>
                <input
                  className="dashboard-button-green"
                  type="submit"
                  value="Change Featured Image"
                ></input>
              </form>
            </div>
            <div className="dashboard-form-col-1 image-gallery">
              <form className="" onSubmit={handleImagesSubmit}>
                <label htmlFor="propedgimg">
                  <div> Add Images to the Gallery:</div>
                </label>
                <div>
                  <label
                    id="fileuploaded"
                    className="imagetray"
                    htmlFor="propedgimg"
                  >
                    <div>Select atleast one image to add to the gallery</div>
                  </label>

                  <input
                    type="file"
                    id="propedgimg"
                    name="filename"
                    multiple
                    ref={imagesRef}
                    onChange={(event) => {
                      var x = document.getElementById("propedgimg");
                      var txt = "";
                      if ("files" in x) {
                        if (x.files.length === 0) {
                          txt =
                            "<div>Select atleast one image to add to the gallery</div>";
                        } else {
                          for (var i = 0; i < x.files.length; i++) {
                            txt += "<div class='gallery-image'>";
                            var file = x.files[i];
                            if ("name" in file) {
                              x.src = URL.createObjectURL(
                                event.target.files[i]
                              );
                              txt +=
                                "<img class='form-img' alt='' src='" +
                                x.src +
                                "'/>";
                            }
                            if ("size" in file) {
                              var size = file.size / 1000000;
                              var sizemb = size.toFixed(2);
                              txt +=
                                "<p class='imgsize'>Size: " +
                                sizemb +
                                " MB</p></div>";
                            }
                          }
                        }
                      }
                      document.getElementById("fileuploaded").innerHTML = txt;
                    }}
                  />
                </div>
                <input
                  className="dashboard-button-green"
                  type="submit"
                  value="Upload Selected Images"
                ></input>
              </form>
            </div>
            <div className="dashboard-form-col-1 full-width">
              {property.attributes.Images.data === null ? (
                <div></div>
              ) : (
                <form onSubmit={handleImagesDeletionSubmit}>
                  <div>
                    <label htmlFor="propedgimgs">
                      Remove Images from Gallery:
                    </label>
                    <div className="imagetray">
                      {property.attributes.Images.data
                        .slice(0, property.attributes.Images.data.length)
                        .map((data, key) => (
                          <div key={key}>
                            <input
                              className="check"
                              type="checkbox"
                              id={data.id}
                              name=""
                              value=""
                              ref={(el) =>
                                (imagesDeletionRef.current[key] = el)
                              }
                            />
                            <label
                              className="img-checked-label"
                              htmlFor={data.id}
                            >
                              <img
                                className="form-img"
                                src={data.attributes.url}
                                alt={""}
                                width={"150px"}
                              />
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                  <input
                    className="dashboard-button-red"
                    type="submit"
                    value="Delete Selected Images"
                  ></input>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPropertyEdit;
