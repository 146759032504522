import React, { useEffect, useState } from "react";
import { managersProperties } from "../../components/Global/Api";
import Loading from "../../components/Global/Loading/Loading";

import DashboardBookingsTable from "../../components/DashboardComponents/DashboardBookingsComponents/DashboardBookingsTable/DashboardBookingsTable";
import "./DashboardBookings.css";
const DashboardBookings = () => {
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const fetchPropertiesBookings = async () => {
    try {
      const { data } = await managersProperties(
        sessionStorage.getItem("token")
      );
      for (let index = 0; index < data.properties.length; index++) {
        for (
          let bookingIndex = 0;
          bookingIndex < data.properties[index].bookings.length;
          bookingIndex++
        ) {
          // tempBookings.push(data.properties[index].bookings[bookingIndex]);
          setBookings((arr) => [
            ...arr,
            data.properties[index].bookings[bookingIndex],
          ]);
        }
      }
      // console.log(data.properties);
      // setBookings(tempBookings);

      console.log(bookings);
      console.log(bookings);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPropertiesBookings();
    console.log(bookings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;
  return (
    <div className="dashboard-properties-wrapper">
      <h1 className="dashboard-properties-title">Bookings</h1>
      <h2 className="dashboard-properties-subtitle">
        Bookings Managed by {"username"}
      </h2>
      <div className="dashboard-properties">
        <div className="booking-grid">
          {bookings.slice(0, bookings.length).map((data, key) => (
            <DashboardBookingsTable
              key={key}
              bookingNo={data.id}
              property={data.property.Name}
              guests={data.number_of_guests}
              checkin={data.check_in}
              checkout={data.check_out}
              status="pending"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardBookings;
