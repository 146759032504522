import React from "react";
import ActivityFishing from "../../../Assets/SVG/ActivityFishing";
import "./AtollInfoWidget.css";

const AtollInfoWidget = (props) => {
  return (
    <div className="atoll-facts-info-grid-content-wrapper info-widget-wrapper">
      <div className="widget-item-row">
        <span className="widget-icon-wrapper">
          <ActivityFishing className="widget-icon" />{" "}
        </span>
        <span>Population: {props.population}</span>
      </div>

      <div className="widget-item-row">
        <span className="widget-icon-wrapper">
          <ActivityFishing className="widget-icon" />{" "}
        </span>
        <span>Guesthouses: {props.guesthouses}</span>
      </div>

      <div className="widget-item-row">
        <span className="widget-icon-wrapper">
          <ActivityFishing className="widget-icon" />{" "}
        </span>
        <span>Homestay: {props.homestays}</span>
      </div>

      <div className="widget-item-row">
        <span className="widget-icon-wrapper">
          <ActivityFishing className="widget-icon" />{" "}
        </span>
        <span>Activities: {props.activities}</span>
      </div>

      <div className="widget-item-row">
        <span className="widget-icon-wrapper">
          <ActivityFishing className="widget-icon" />{" "}
        </span>
        <span>Islands in Atoll: {props.islandsInAtoll}</span>
      </div>

      <div className="widget-item-row">
        <span className="widget-icon-wrapper">
          <ActivityFishing className="widget-icon" />{" "}
        </span>
        <span>Restaurants: {props.restaurants}</span>
      </div>
    </div>
  );
};

export default AtollInfoWidget;
