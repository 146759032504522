import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../../Assets/Images/logo.png";
import { faSearch, faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./NavBar.css";
import { useEffect, useState } from "react";

const NavBar = () => {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const handleSearch = () => {
    navigate("/results/" + query);
  };
  useEffect(() => {
    const menuBtn = document.querySelector(".menu-icon span");
    const menuBtnWrapper = document.querySelector(".menu-icon");
    const searchBtn = document.querySelector(".search-icon span");
    const searchBtnWrapper = document.querySelector(".search-icon");
    const cancelBtn = document.querySelector(".cancel-icon");
    const menuCancelBtn = document.querySelector(".menu-cancel");
    const items = document.querySelector(".nav-items");
    const menuItems = document.querySelectorAll(".nav-items li a");
    const form = document.querySelector("form");
    menuBtn.onclick = () => {
      items.classList.add("active");
      menuBtnWrapper.classList.add("hide");
      searchBtn.classList.add("hide");
      menuCancelBtn.classList.add("show");
      menuCancelBtn.style.color = "#0193CC";
    };
    menuItems.forEach(
      (elem) =>
        (elem.onclick = () => {
          items.classList.remove("active");
          menuBtnWrapper.classList.remove("hide");
          searchBtn.classList.remove("hide");
          menuCancelBtn.classList.remove("show");
          form.classList.remove("active");
        })
    );
    cancelBtn.onclick = () => {
      items.classList.remove("active");
      menuBtn.classList.remove("hide");
      searchBtn.classList.remove("hide");
      searchBtnWrapper.classList.remove("hide");
      menuBtnWrapper.classList.remove("hide");
      cancelBtn.classList.remove("show");
      form.classList.remove("active");
      cancelBtn.style.color = "#0193CC";
    };
    menuCancelBtn.onclick = () => {
      items.classList.remove("active");
      menuBtnWrapper.classList.remove("hide");
      searchBtn.classList.remove("hide");
      menuCancelBtn.classList.remove("show");
      form.classList.remove("active");
      menuCancelBtn.style.color = "#0193CC";
    };
    searchBtn.onclick = () => {
      form.classList.add("active");
      searchBtnWrapper.classList.add("hide");
      menuBtn.classList.add("hide");
      searchBtn.classList.add("hide");
      cancelBtn.classList.add("show");
      cancelBtn.style.color = "#0193CC";
    };
  }, []);

  return (
    <nav>
      {/* <Notice /> */}
      <div className="search-icon">
        <span>
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </div>
      <div className="cancel-icon">
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="logo">
        <Link to="/">
          <img alt="MVSTAY" src={logo} />
        </Link>
      </div>
      <div className="menu-icon">
        <span>
          <FontAwesomeIcon icon={faBars} />
        </span>
      </div>
      <div className="cancel-icon menu-cancel">
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <form onSubmit={handleSearch}>
        <input
          type="search"
          className="search-data"
          placeholder="Search"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          required
        />
        <button type="submit">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </form>
      <div className="nav-items">
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to="/"
          >
            home
          </NavLink>
        </li>

        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to="/destinations"
          >
            Destinations
          </NavLink>
        </li>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to="/activities"
          >
            Activities
          </NavLink>
        </li>
        <li>
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to="/packages"
          >
            Hot Deals
          </NavLink>
        </li>
      </div>
    </nav>
  );
};

export default NavBar;
