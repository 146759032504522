import React from "react";
import "./IslandInfoWidget.css";
import WidgetActivities from "../../../Assets/SVG/WidgetActivities";
import WidgetGuesthouse from "../../../Assets/SVG/WidgetGuesthouse";
import WidgetHomestay from "../../../Assets/SVG/WidgetHomestay";
import WidgetPopulation from "../../../Assets/SVG/WidgetPopulation";
import WidgetRestaurants from "../../../Assets/SVG/WidgetRestaurants";


const IslandInfoWidget = (props) => {
  return (
    <div className="island-info-widget-wrapper">
      <div className="island-widget-item-row">
        <span className="island-widget-icon-wrapper">
          <WidgetPopulation className="island-widget-icon" />{" "}
        </span>
        <span>Population: {props.population}</span>
      </div>

      <div className="island-widget-item-row">
        <span className="island-widget-icon-wrapper">
          <WidgetGuesthouse className="island-widget-icon" />{" "}
        </span>
        <span>Guesthouses: 6</span>
      </div>

      <div className="island-widget-item-row">
        <span className="island-widget-icon-wrapper">
          <WidgetHomestay className="island-widget-icon" />{" "}
        </span>
        <span>Homestay: 6</span>
      </div>

      <div className="island-widget-item-row">
        <span className="island-widget-icon-wrapper">
          <WidgetActivities className="island-widget-icon" />
        </span>
        <span>Activities: 8</span>
      </div>

      <div className="island-widget-item-row">
        <span className="island-widget-icon-wrapper">
          <WidgetRestaurants className="island-widget-icon" />{" "}
        </span>
        <span>Islands in island: 10</span>
      </div>
    </div>
  );
};

export default IslandInfoWidget;
