import {
  faLocationDot,
  faPenToSquare,
  faPeopleRoof,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import "./DashboardPropertyCard.css";
const DashboardPropertyCard = (props) => {
  return (
    <div className="dashboard-card">
      <div
        className="dashboard-card-image"
        style={{ backgroundImage: "url(" + props.image.url + ")" }}
        // src="https://images.unsplash.com/photo-1614440455073-bd7fa8669eb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
      ></div>
      <div className="dashboard-card-details">
        <h3 className="dashboard-card-type">{props.type}</h3>
        <h2 className="dashboard-card-title">{props.name}</h2>
        <ReactStars
          size={24}
          color={"#ccc"}
          value={props.rating}
          edit={false}
          isHalf={true}
        />
        <div className="dashboard-card-quick-facts">
          <div className="dashboard-card-quick-fact">
            <span className="dashboard-card-icon-wrapper">
              <FontAwesomeIcon icon={faLocationDot} />
            </span>
          </div>
          <div className="dashboard-card-quick-fact">
            <span className="dashboard-card-icon-wrapper">
              <FontAwesomeIcon icon={faPeopleRoof} />
            </span>
            <span>{"6"} Rooms</span>
          </div>
        </div>
      </div>

      <div className="dashboard-card-controls">
        <Link
          className="dashboard-card-button"
          to={"/dashboard/properties/edit/" + props.id}
        >
          <FontAwesomeIcon icon={faPenToSquare} /> Edit{" "}
        </Link>
      </div>
    </div>
  );
};

export default DashboardPropertyCard;
