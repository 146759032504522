import "./MapActivities.css";
import SvgIcon from "../../Global/SvgIcon";

const MapActivities = (props) => {
  const activities = props.activities;
  if (props.activities === undefined) return null;
  return (
    <div className="Activity_Icon_Wrapper">
      {activities.length > 0 &&
        activities.map((data, key) => (
          <div key={key}>
            <div className="Activity_Icon_Box">
              <SvgIcon
                className="Activity_Icon"
                icon={data.attributes.svg_icon.data.attributes.SVGPath}
                viewBox={data.attributes.svg_icon.data.attributes.viewbox}
              />
            </div>
            <h4>{data.attributes.Name}</h4>
          </div>
        ))}
    </div>
  );
};

export default MapActivities;
