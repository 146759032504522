import React from "react";
import "./Footer.css";
import logo from "../../../Assets/Images/logo_white.png";
import { Link } from "react-router-dom";

const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <div className="footer-container">
      <img src={logo} alt="MVSTAY" width={150} />
      <div className="links">
        <ul>
          <li>
            <Link to={"/privacy-policy"}>Privacy Policy</Link>
          </li>
          <li>
            <Link to={"/terms-of-service"}>Terms of Service</Link>
          </li>
        </ul>
      </div>
      <div>
        <p>
          &copy;{year > 2022 ? " 2022 - " : ""} {year} MVStay. All Rights
          Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
