import React, { useEffect, useState } from "react";
import DashboardQuotationsTable from "../../components/DashboardComponents/DashboardQuotationsComponents/DashboardQuotationsTable";
import { managersProperties } from "../../components/Global/Api";
import Loading from "../../components/Global/Loading/Loading";
import "./DashboardQuotations.css";
const DashboardQuotations = () => {
  const [loading, setLoading] = useState(true);
  const [quotations, setQuotations] = useState([]);

  const fetchPropertiesQuotations = async () => {
    try {
      const { data } = await managersProperties(
        sessionStorage.getItem("token")
      );
      for (let index = 0; index < data.properties.length; index++) {
        for (
          let bookingIndex = 0;
          bookingIndex < data.properties[index].bookings.length;
          bookingIndex++
        ) {
          for (
            let quotationIndex = 0;
            quotationIndex <
            data.properties[index].bookings[bookingIndex].quotations.length;
            quotationIndex++
          ) {
            let iii = {
              ...data.properties[index].bookings[bookingIndex].quotations[
                quotationIndex
              ],
              property:
                data.properties[index].bookings[bookingIndex].property.Name,
              GuestName:
                data.properties[index].bookings[bookingIndex].Guest_Name,
              bookingNo: data.properties[index].bookings[bookingIndex].id,
              numberOfGuests:
                data.properties[index].bookings[bookingIndex].number_of_guests,
              t: "adfdsd",
            };

            setQuotations((arr) => [...arr, iii]);
          }
        }
      }
      console.log(quotations);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPropertiesQuotations();
    console.log(quotations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <Loading />;

  return (
    <div className="dashboard-properties-wrapper">
      <h1 className="dashboard-properties-title">Quotations</h1>
      <h2 className="dashboard-properties-subtitle">
        Quotations for bookings Managed by {"username"}
      </h2>
      <div className="dashboard-properties">
        <div className="booking-grid">
          {quotations.slice(0, quotations.length).map((data, key) => (
            <DashboardQuotationsTable
              key={key}
              bookingNo={data.bookingNo}
              quotationNo={data.id}
              property={data.property}
              guests={data.numberOfGuests}
              checkin={data.check_in}
              checkout={data.check_out}
              total={data.Total}
              status="pending"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardQuotations;
