import React from "react";
import "./IslandActivitiesSlide.css";

const IslandActivitiesSlide = (props) => {
  return (
    <div>
      <div className="island-activities-slide">
        <div
          className="island-activities-image"
          style={{
            backgroundImage:
              "url(" + props.activity.FeaturedImage.data.attributes.url + ")",
          }}
        ></div>
        <div className="island-activities-slide-col-2">
          <div className="island-activities-description">
            <h3>{props.activity.Name}</h3>
            <p>{props.activity.Paragraph}</p>
          </div>
          <div className="island-activities-gallery">
            <div
              className="island-activities-gallery-image"
              style={{
                backgroundImage:
                  "url(" +
                  props.activity.FeaturedImage.data.attributes.url +
                  ")",
              }}
            ></div>
            <div
              className="island-activities-gallery-image"
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1614440455073-bd7fa8669eb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
              }}
            ></div>
            <div
              className="island-activities-gallery-image"
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1614440455073-bd7fa8669eb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
              }}
            ></div>
            <div
              className="island-activities-gallery-image"
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1614440455073-bd7fa8669eb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
              }}
            ></div>
            <div
              className="island-activities-gallery-image"
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1614440455073-bd7fa8669eb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
              }}
            ></div>
            <div
              className="island-activities-gallery-image"
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1614440455073-bd7fa8669eb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IslandActivitiesSlide;
