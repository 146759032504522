import {
  faDollarSign,
  faPlaneArrival,
  faPlaneDeparture,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { getAllActivities } from "../../Global/Api";
import "./QuickBookingForm.css";
import ms from "ms";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const QuickBookingForm = () => {
  const [userinfo, setUserInfo] = useState({
    activities: [],
    response: [],
  });

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [budget, setBudget] = useState(null);
  const [request, setRequest] = useState(null);
  const [numberOfGuests, setNumberOfGuests] = useState(null);

  useEffect(() => {
    const mincheckin = ms("14d");
    const mincheckout = ms("1d");
    const min_date = new Date(+new Date(checkIn) + mincheckout);
    const max_date = new Date(+new Date() + mincheckin);
    setMinDate(min_date);
    setMaxDate(max_date);
  }, [checkIn]);

  const [activitiesList, setActivitiesList] = useState();

  const fetchActivities = async () => {
    try {
      const { data, status } = await getAllActivities();
      if (status === 200) {
        console.log(data);
        setActivitiesList(data.data);
      }
    } catch (error) {
      console.log(error);
      Navigate("../404");
    }
  };

  useEffect(() => {
    console.log(userinfo);
  }, [userinfo]);
  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { activities } = userinfo;

    // console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        activities: [...activities, value],
        response: [...activities, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        activities: activities.filter((e) => e !== value),
        response: activities.filter((e) => e !== value),
      });
    }
  };
  useEffect(() => {
    fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (activitiesList === undefined) return null;
  return (
    <div className="q-booking-form">
      <div className="half-width grow">
        <h2 className="form-label">Check-In Date</h2>
        <div className="field-wrap">
          <span className="field-icon">
            <FontAwesomeIcon icon={faPlaneArrival} />
          </span>
          <DatePicker
            selected={checkIn}
            onChange={(event) => setCheckIn(event)}
            minDate={maxDate}
            placeholderText="click to select a date"
          />
        </div>
      </div>
      <div className="half-width grow">
        <h2 className="form-label">Check-Out Date</h2>
        <div className="field-wrap">
          <span className="field-icon">
            <FontAwesomeIcon icon={faPlaneDeparture} />
          </span>
          <DatePicker
            selected={checkOut}
            onChange={(event) => setCheckOut(event)}
            minDate={minDate}
            placeholderText="click to select a date"
          />
        </div>
      </div>
      <div className="half-width grow">
        <h2 className="form-label">Give us a budget</h2>
        <div className="field-wrap">
          <span className="field-icon">
            <FontAwesomeIcon icon={faDollarSign} />
          </span>
          <input
            type="number"
            max={1000000}
            min={500}
            step={500}
            className="field"
            onChange={(e) => setBudget(e.target.value)}
          />
        </div>
      </div>

      <div className="half-width grow">
        <h2 className="form-label">Number of Guests</h2>
        <div className="field-wrap">
          <span className="field-icon">
            <FontAwesomeIcon icon={faUserGroup} />
          </span>
          <input
            type="number"
            max={20}
            min={1}
            step={1}
            className="field"
            onChange={(e) => setNumberOfGuests(e.target.value)}
          />
        </div>
      </div>
      <div className="grow full-width">
        <h2 className="form-label">Choose Activities</h2>
        <div className="booking-buttons-container">
          {activitiesList.length > 0 &&
            activitiesList.map((data, key) => (
              <div key={key}>
                <input
                  type="checkbox"
                  id={"checkbox " + data.id}
                  className="check"
                  name="activities"
                  value={data.attributes.Name}
                  onChange={handleChange}
                />
                <label
                  htmlFor={"checkbox " + data.id}
                  className="checked-label"
                >
                  {data.attributes.Name}
                </label>
              </div>
            ))}
        </div>
      </div>
      <div className="grow full-width">
        <h2 className="form-label">Special Requests</h2>
        <textarea
          name="comment"
          placeholder={
            "For example: Want to have a beach-side bbq with Family and Friends"
          }
          onChange={(e) => setRequest(e.target.value)}
        ></textarea>
        {/* {userinfo.response.map((response, key) => (
          <li key={key}>{response}</li>
        ))} */}
      </div>
      <div>
        <div className="form-submit">
          <Link
            to="/booking"
            state={{
              activities: userinfo.activities,
              checkIn: checkIn,
              checkOut: checkOut,
              budget: budget,
              specialRequest: request,
              numberOfGuests: numberOfGuests,
            }}
          >
            <div className="BlueButton">click to comfirm </div>
          </Link>
          {/* <span>Click to confirm</span> */}

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuickBookingForm;
