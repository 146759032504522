import React from "react";
import "./PackagesHero.css";

const PackagesHero = () => {
  return (
    <div className="packages-hero">
      <h1 className="britania">
        Amazing Deals. <br></br>Worth The Fun
      </h1>
    </div>
  );
};

export default PackagesHero;
