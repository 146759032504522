import axios from "axios";
// const qs = require('qs');
const DATABASE = process.env.REACT_APP_DATABASE_URL;
// const TOKEN = process.env.REACT_APP_BEARER_TOKEN;

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else delete axios.defaults.headers.common["Authorization"];
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const removeDashes = (string) => {
  return string.replace(/-/g, " ");
};

export const getAtoll = (atollCode) => {
  return axios.get(
    DATABASE +
      "/atolls?populate[0]=islands&populate[1]=activities.svg_icon.SVGPath&populate[2]=map&populate[3]=Images&populate[4]=Featured_Image&populate[5]=tags&populate[6]=islands.Featured_Image&populate[7]=activities.Image&populate[8]=islands.properties&populate[9]=islands.properties.Featured_Image&populate[10]=activities.islands.atoll&filters[AtollCode][$eq]=" +
      atollCode
  );
};

export const getAllAtolls = async (atollCode) => {
  // return await axios.get(DATABASE + "/atolls?populate=%2A&sort=id:asc", {
  return await axios.get(
    DATABASE +
      "/atolls?populate[0]=islands&populate[1]=activities.svg_icon.SVGPath&populate[2]=map&populate[3]=Images&populate[4]=Featured_Image&populate[5]=tags&populate[6]=islands.properties&sort=id:asc"
  );
};

export const getIsland = async (islandName, atollCode) => {
  const islands = await axios.get(
    DATABASE +
      "/islands?populate[0]=properties.Featured_Image&populate[1]=atoll&populate[2]=map&populate[3]=Images&populate[4]=activities&populate[5]=special_activities.FeaturedImage&filters[Name][$eq]=" +
      capitalizeFirstLetter(islandName)
  );

  if (
    islands.data.data[0].attributes.atoll.data.attributes.AtollCode ===
    atollCode
  ) {
    return islands.data.data[0];
  } else if (
    islands.data.data[1].attributes.atoll.data.attributes.AtollCode ===
    atollCode
  ) {
    return islands.data.data[1];
  } else {
    return null;
  }
  // console.log(islands.data.data[0].attributes.atoll.data.attributes.AtollCode);
  // return islands;
};
export const getHomePage = () => {
  return axios.get(DATABASE + "/homepage?populate=%2A");
};
export const getAllActivities = () => {
  return axios.get(
    DATABASE +
      "/activities?populate[0]=svg_icon.SVGPath&populate[1]=Image&populate[2]=islands&populate[3]=properties&populate[4]=properties.Featured_Image&populate[5]=properties.island.atoll&populate[6]=islands.atoll"
  );
};
export const getAllSpecialActivities = () => {
  return axios.get(
    DATABASE +
      "/special-activities?populate[0]=activity.svg_icon.SVGPath&populate[1]=islands.atoll&populate[2]=FeaturedImage"
  );
};

export const getActivityHomepage = () => {
  return axios.get(DATABASE + "/activitiespage");
};

export const getProperty = (name) => {
  return axios.get(
    DATABASE +
      "/properties?populate[0]=Featured_Image&populate[1]=amenities.svg_icon.SVGPath&populate[2]=Images&populate[3]=island.atoll&populate[4]=island.activities.Image&filters[Name][$eq]=" +
      removeDashes(name)
  );
};
export const getPropertyById = (id) => {
  return axios.get(
    DATABASE +
      "/properties/" +
      id +
      "?populate[0]=Featured_Image&populate[1]=amenities.svg_icon.SVGPath&populate[2]=Images&populate[3]=island"
  );
};

export const getIslandByID = (id) => {
  return axios.get(
    DATABASE +
      "/islands/" +
      id +
      "?populate[0]=Featured_Image&populate[1]=Images&populate[2]=activities.svg_icon.SVGPath&populate[3]=atoll&populate[4]=properties"
  );
};

export const getPackages = () => {
  return axios.get(
    DATABASE +
      "/packages?populate[0]=Featured_Image&populate[1]=Images&populate[2]=island&populate[3]=property&populate[4]=package_features&populate[5]=package_features.svg_icon.SVGPath&populate[6]=island.atoll&populate[7]=activities&populate[8]=activities.svg_icon.SVGPath"
  );
};
export const login = async (identifier, password) => {
  const res = await axios.post(DATABASE + "/auth/local", {
    identifier: identifier,
    password: password,
  });
  sessionStorage.setItem("token", res.data.jwt);
  // console.log('User profile', response.data.user);
  // console.log('User token', response.data.jwt);
  return res.data;
};

export const managersProperties = (token) => {
  console.log(token);
  return axios.get(
    DATABASE +
      "/users/me?populate[0]=properties.Featured_Image&populate[1]=properties.Images&populate[2]=properties.bookings.property&populate[3]=properties.bookings.quotations",
    {
      headers: {
        Authorization: "bearer " + token,
      },
    }
  );
};

export const managersPropertiesChange = async (token, id, data) => {
  const res = await axios.put(
    DATABASE + "/properties/" + id,
    {
      data: data,
    },
    {
      headers: {
        Authorization: "bearer " + token,
      },
    }
  );
  return res;
};

export const imageUpload = async (token, id, data) => {
  const res = await axios.post(DATABASE + "/upload", data, {
    headers: {
      Authorization: "bearer " + token,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
export const deleteImage = async (token, id) => {
  const res = await axios.delete(DATABASE + "/upload/files/" + id, {
    headers: {
      Authorization: "bearer " + token,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
export const managersPropertiesImageDeletion = async (token, id) => {
  const res = await axios.delete(DATABASE + "/Images/" + id, {
    headers: {
      Authorization: "bearer " + token,
    },
  });
  return res;
};
export const getBookingById = async (token, id) => {
  const res = await axios.get(DATABASE + "/bookings/" + id + "?populate=*", {
    headers: {
      Authorization: "bearer " + token,
    },
  });
  return res;
};
export const makeBooking = async (data) => {
  console.log(data);
  return await axios.post(DATABASE + "/bookings/", { data: data });
};

export const makeQuotation = async (token, data) => {
  const res = await axios.post(
    DATABASE + "/quotations",
    { data: data },
    {
      headers: {
        Authorization: "bearer " + token,
      },
    }
  );
  return res;
};

export const search = async (query) => {
  const res = await axios.get(DATABASE + "/search?query=" + query);
  return res;
};
