import React from "react";
import "./DashboardQuotations.css";
const DashboardIslands = () => {
  return (
    <div>
      <div>This is where all islands go</div>
    </div>
  );
};

export default DashboardIslands;
