import React from "react";
import "./PackageGrid.css";
import SinglePackage from "./SinglePackage";
const PackageGrid = (props) => {
  if (typeof props.packages.data === "undefined") return null;
  // console.log(props.packages.data);
  return (
    <div className="package-grid-container">
      <h1 className="britania">Prepacked Hot Deals, Just For You</h1>
      <p className="section-p">
        The beauty of the Maldives is not only above the water. The Maldives is
        home to about five percent of the planet’s reefs that comes with an
        explosion of color contributed by soft and hard corals that form them.
        The reefs are home to a thousand species of fish. Lured by the rich
        nutrients that flow in with the currents, large pelagic fishes such as
        manta rays and whale sharks also make the Maldives their home.
      </p>

      <div className="package-grid">
        {props.packages.data
          .slice(0, props.packages.data.length)
          .map((data, key) => (
            <SinglePackage key={key} package={data} />
          ))}
      </div>
    </div>
  );
};

export default PackageGrid;
