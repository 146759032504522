import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllSpecialActivities } from "../../components/Global/Api";
import PopularActivity from "./PopularActivity";
import "./PopularActivityGrid.css";

const PopularActivityGrid = (props) => {
  let navigate = useNavigate();
  const [specialActivities, setSpecialActivities] = useState(0);
  const [filter, setFilter] = useState("*");
  const fetchSpecialActivities = async () => {
    try {
      const { data, status } = await getAllSpecialActivities();
      if (status === 200) {
        setSpecialActivities(data);
      }
    } catch (error) {
      console.log(error);
      navigate("../404");
    }
  };
  const changeFilter = (name) => {
    setFilter(name);
  };
  useEffect(() => {
    fetchSpecialActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (typeof props.activities.data === "undefined") return null;
  if (typeof specialActivities.data === "undefined") return null;

  return (
    <div>
      <div className="filter">
        <h5>Filter:</h5>
        <button onClick={() => changeFilter("*")}> All Activities</button>
        {props.activities.data
          .slice(0, props.activities.data.length)
          .map((data, key) => (
            <button
              onClick={() => changeFilter(data.attributes.Name)}
              key={key}
            >
              {data.attributes.Name}
            </button>
          ))}
      </div>
      {specialActivities.data
        .slice(0, specialActivities.data.length)
        .map((data, key) => (
          <PopularActivity
            key={key}
            image={data.attributes.FeaturedImage}
            name={data.attributes.Name}
            paragraph={data.attributes.Paragraph}
            activity={data.attributes.activity.data.attributes}
            islands={data.attributes.islands}
            filter={filter}
          />
        ))}
    </div>
  );
};

export default PopularActivityGrid;
