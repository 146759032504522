import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faGear } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "./DashboardBooking.css";
import { Link } from "react-router-dom";

const DashboardBookingsTable = (props) => {
  return (
    <div className="dashboard-card">
      <div className="booking-details">
        <div className="booking-detail-cell">
          <h4 className="booking-detail-label">Booking No.</h4>
          <span className="booking-detail-value">{props.bookingNo}</span>
        </div>
        <div className="booking-detail-cell span">
          <h4 className="booking-detail-label">Property</h4>
          <span className="booking-detail-value">{props.property}</span>
        </div>
        <div className="booking-detail-cell">
          <h4 className="booking-detail-label">No. of Guests</h4>
          <span className="booking-detail-value">{props.guests}</span>
        </div>
        <div className="booking-detail-cell">
          <h4 className="booking-detail-label">Check-in</h4>
          <span className="booking-detail-value">{props.checkin}</span>
        </div>
        <div className="booking-detail-cell">
          <h4 className="booking-detail-label">Check-out</h4>
          <span className="booking-detail-value">{props.checkout}</span>
        </div>

        <div className="booking-detail-cell">
          <h4 className="booking-detail-label">Status</h4>
          {/* add class names based on status currently I added status-cancelled status-pending and status-active */}
          <span className={"booking-detail-status status-" + props.status}>
            <FontAwesomeIcon icon={faCircle} />
          </span>
          <span className="booking-detail-value capitalize">
            {props.status}
          </span>
        </div>

        <div className="booking-detail-cell">
          <Link
            className="booking-detail-button"
            to={"/dashboard/bookings/" + props.bookingNo}
          >
            <FontAwesomeIcon icon={faGear} /> Manage{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardBookingsTable;
