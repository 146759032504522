import React, { useEffect, useState } from "react";
import IslandActivities from "../../components/islandComponents/IslandActivities/IslandActivities";
import IslandGuesthouses from "../../components/islandComponents/IslandGuesthouses/IslandGuesthouses";
import IslandHeader from "../../components/islandComponents/IslandHeader/IslandHeader";
import "./Island.css";
import { getIsland } from "../../components/Global/Api";
import { useParams } from "react-router-dom";
import Loading from "../../components/Global/Loading/Loading";

const Island = (props) => {
  const [island, setIsland] = useState([]);
  const [islandID, setIslandID] = useState([]);
  const [loading, setLoading] = useState(false);
  const { islandName, atollCode } = useParams();

  const fetchIsland = async () => {
    setLoading(true);
    try {
      const { id, attributes } = await getIsland(islandName, atollCode);
      // if (status === 200) {
      // console.log(data);
      setIsland(attributes);
      setIslandID(id);
      setLoading(false);
      // }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchIsland();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [island]);
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <IslandHeader
        island={island}
        name={island.Name}
        description={island.Description}
        population={island.Population}
        latitude={island.map?.lat}
        longitude={island.map?.lng}
        zoom={island.map?.zoom}
      />
      <IslandActivities island={island} />
      <IslandGuesthouses
        properties={island.properties}
        island={island}
        name={island.Name}
        islandID={islandID}
      />
    </div>
  );
};

export default Island;
