import * as React from "react"

const WidgetHomestay = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width="1em"
    height="1em"
    {...props}
  >
    
    <path
      className="cls-1"
      d="M500 234.542c-1.215 1.219-2.578 2.324-3.623 3.675-10.243 13.234-20.433 26.508-30.896 40.107L249.998 112.538 34.517 278.323 0 233.475 249.984 41.16l106.554 81.93V88.684h72.604v5.774c0 26.685.057 53.37-.072 80.054-.015 3.285 1.038 5.31 3.633 7.291 22.494 17.173 44.88 34.49 67.297 51.763v.977Z"
    />
    <path
      className="cls-1"
      d="M250.047 149.591c36.086 27.766 71.804 55.246 107.52 82.73 20.374 15.677 40.781 31.31 61.044 47.13 1.623 1.268 3.171 3.83 3.177 5.796.168 56.965.135 113.931.127 170.897 0 .793-.094 1.586-.165 2.697H289.676V329.587h-73.34v129.188H78.07V454c0-55.827.033-111.653-.081-167.48-.007-3.469 1.08-5.538 3.815-7.636 54.48-41.787 108.877-83.685 163.292-125.557 1.535-1.182 3.093-2.335 4.95-3.735Z"
    />
  </svg>
)

export default WidgetHomestay