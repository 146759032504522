import React from 'react'


const Welcome = () => {
  return (
    <div>
    
      <p>Welcome To MVSTAY</p>
    </div>
  )
}

export default Welcome
