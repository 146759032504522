import React from "react";
import "./MapDetails.css";
import "react-loading-skeleton/dist/skeleton.css";
import MapActivities from "./MapActivities";
import FamousFor from "./FamousFor";
import RightChevron from "../../../Assets/SVG/RightChevron";
import { Link } from "react-router-dom";
import GoogleMap from "../../Global/GoogleMap/GoogleMap";
import Gallery from "../../Global/Gallery/Gallery";



const MapDetails = (props) => {
  if (typeof props.displayatoll === "undefined") return null;
  const renderAtoll = props.displayatoll;
  return (
    <div className="Map_Details_container" >
      
      <h2  id="detailsStart" >
        <span>{renderAtoll.attributes.Name} Atoll, Maldives</span>
      </h2>
      <GoogleMap
        latitude={renderAtoll.attributes.map.lat}
        longitude={renderAtoll.attributes.map.lng}
        zoom={renderAtoll.attributes.map.zoom}
      />
      <h3>Gallery</h3>
      <Gallery qty={9} wrapperStyle={"MapDetailsGallery_wrapper"} itemStyle={"GalleryItem"} images={renderAtoll.attributes.Images} />
      {renderAtoll.attributes.activities.data.length > 0 && <h3>Activities</h3>}

      <MapActivities activities={renderAtoll.attributes.activities.data} />
      
      {renderAtoll.attributes.tags.length > 0 && <h3>Famous For</h3>}
      <FamousFor tags={renderAtoll.attributes.tags} />
      <h2>{renderAtoll.attributes.Name} Atoll, Maldives</h2>
      <p>{renderAtoll.attributes.Description}</p>
      <Link
        className="BlueButton"
        to={"/atoll/" + renderAtoll.attributes.AtollCode}
      >
        Take Me There <RightChevron />{" "}
      </Link>
    </div>
  );
};

export default MapDetails;
