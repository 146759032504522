import React from "react";
import "./AtollFeaturedislands.css";
import AtollFeaturedIslandSlider from "./AtollFeaturedIslandSlider";

const AtollFeaturedIslands = (props) => {
  return (
    <div className="atoll-featured-islands">
      <h1 className="section-h1">Featured Islands in {props.atoll.Name}</h1>
      <AtollFeaturedIslandSlider islands={props.islands} atoll={props.atoll}/>
      
    </div>
  );
};

export default AtollFeaturedIslands;
