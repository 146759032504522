import React from "react";
import Gallery from "../../Global/Gallery/Gallery";
import "./PropertyHeader.css";
import PropertyInfo from "./PropertyInfo";

const PropertyHeader = (props) => {
  return (
    <div className="property-header">
      <div>
        <Gallery
          qty={4}
          wrapperStyle={"atoll-gallery-wrapper"}
          itemStyle={"atoll-image"}
          images={props.property.Images}
        />
      </div>
      <div className="property-header-col-2">
        <PropertyInfo
          name={props.name}
          description={props.description}
          type={props.type}
          amenities={props.amenities}
          rating={props.rating}
          property={props.property}
        />
      </div>
    </div>
  );
};

export default PropertyHeader;
