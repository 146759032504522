import * as React from "react"

const BookingTwo = ({ title, titleId, ...props }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M452.538 500H74.103v-89.075l183.602-173.388c17.798-16.987 29.852-31.52 35.767-43.153 5.632-11.091 8.494-22.103 8.494-32.733 0-14.376-4.51-24.927-13.789-32.246-10.122-7.992-25.968-12.041-47.109-12.041-18.266 0-34.877 3.468-49.37 10.326-14.211 6.726-26.318 16.987-35.966 30.505l-7.484 10.472-100.786-64.914 7.682-11.42c19.44-28.923 46.51-51.831 80.443-68.093C169.145 8.164 207.992 0 251.032 0c36.262 0 68.587 6.08 96.078 18.068 28.085 12.24 50.333 29.898 66.113 52.464 15.952 22.842 24.036 49.918 24.036 80.489 0 27.168-5.81 53.057-17.277 76.915-11.467 23.91-32.654 50.01-64.768 79.803L273.05 385.3h179.488V500Zm-351.426-27.01h324.416v-60.68H205.09l131.667-124.3c29.258-27.13 49.034-51.251 58.88-71.76 9.693-20.19 14.612-42.136 14.612-65.23 0-25.281-6.27-46.554-19.162-65.018-12.885-18.424-31.31-32.945-54.772-43.166-24.069-10.498-52.76-15.826-85.283-15.826-38.972 0-73.882 7.267-103.773 21.59-24.992 11.974-45.626 27.985-61.511 47.689l56.018 36.083c10.867-12.41 23.74-22.17 38.385-29.107 18.134-8.572 38.629-12.911 60.917-12.911 27.366 0 48.85 6 63.845 17.844 15.965 12.608 24.063 30.584 24.063 53.453 0 14.929-3.845 30.056-11.435 44.972-7.398 14.56-20.89 31.072-41.24 50.499L101.112 422.557v50.433Z"
     
    />
  </svg>
)

export default BookingTwo
