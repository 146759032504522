import React, { useState, useEffect } from "react";
import "./AtollFacts.css";
import Chart from "../../../Assets/SVG/raw/chart 1.svg";
// import MiniMap from "../../../Assets/SVG/MiniMap";
import Image from "../../../Assets/Images/mvstayplaceholder.jpg";
import { Link, Navigate } from "react-router-dom";
import RightChevron from "../../../Assets/SVG/RightChevron";
import SvgIcon from "../../Global/SvgIcon";

import AtollInfoWidget from "./AtollInfoWidget";
import PopularTags from "./PopularTags";
import { getIslandByID } from "../../Global/Api";

const AtollFacts = (props) => {
  const [island, setIsland] = useState();
  const [islandID, setIslandID] = useState(props.atoll.islands?.data[0].id);
  const [shortdesc, setShortdesc] = useState();
  const islandNames = Array.from(
    document.getElementsByClassName("atoll-facts-info-grid-column-name")
  );
  const islandNameClick = (e, id) => {
    setIslandID(id);
    const selectedIsland = e.target;
    islandNames.forEach((islandName) => {
      islandName.classList.remove("active");
    });
    selectedIsland.classList.add("active");
  };

  const excerpt = (string) => {
    let str = string;
    if (str !== undefined && str.length > 250)
      str = str.substring(0, 250) + "...";
    setShortdesc(str);
  };

  const fetchIsland = async () => {
    if (islandID !== undefined)
      try {
        const { data, status } = await getIslandByID(islandID);
        if (status === 200) {
          setIsland(data);
          console.log(island);
        }
      } catch (error) {
        console.log(error);
        Navigate("../404");
      }
  };

  useEffect(() => {
    fetchIsland();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islandID]);

  useEffect(() => {
    console.log(island?.data.attributes.Description);
    excerpt(island?.data.attributes.Description);
    console.log(shortdesc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [island]);
  // useEffect(() => {
  //   if (props.atoll.islands.data.length > 0)
  //     setIslandID(props.atoll.islands.data[0].id);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.atoll]);
  // let navigate = useNavigate();

  // function miniAtollClickHandler(e, i) {
  //   navigate("../atoll/" + e);
  // }

  if (props.atoll?.islands.data.length === 0) return null;
  return (
    <div className="atoll-facts">
      <h2 className="britania">{props.atoll.Name} Atoll</h2>
      <img className="atoll-detailed-map" alt="" src={Chart} />

      <div className="atoll-facts-info-grid">
        <div className="atoll-facts-info-grid-column span3">
          <span className="tip">
            Click on an Island to find more information
          </span>
          <div className="atoll-facts-info-grid-column-name-wrapper">
            <div className="atoll-facts-info-grid-column-span">
              {props.atoll.islands.data.length > 0 &&
                props.atoll.islands.data.map((data, key) => (
                  <span
                    key={key}
                    onClick={(e) => islandNameClick(e, data.id)}
                    className={
                      key === 0
                        ? "atoll-facts-info-grid-column-name active"
                        : "atoll-facts-info-grid-column-name"
                    }
                  >
                    {data.attributes.Name}
                  </span>
                ))}
            </div>
          </div>
        </div>

        <div className="atoll-facts-info-grid-column">
          <div className="atoll-facts-info-grid-content-wrapper">
            {island && island.data.attributes.Featured_Image.data !== null ? (
              <img
                className="atoll-facts-info-grid-image"
                src={island.data.attributes.Featured_Image.data?.attributes.url}
                alt=""
              />
            ) : (
              <img className="atoll-facts-info-grid-image" src={Image} alt="" />
            )}

            <h3>
              {island && island.data.attributes.Name},{" "}
              {island && island.data.attributes.atoll.data.attributes.Name}{" "}
              Atoll
            </h3>
            <p>{island && shortdesc}</p>
            {island && (
              <Link
                className="WhiteButton"
                to={
                  "/island/" +
                  props.atoll.AtollCode +
                  "/" +
                  island?.data.attributes.Name
                }
              >
                Take Me There <RightChevron />
              </Link>
            )}
            {/* Activity Icons */}
            <div className="atoll-info-activity-grid">
              {island &&
                island.data.attributes.activities.data.length > 0 &&
                island.data.attributes.activities.data.map((data, key) => (
                  <div className="atoll-info-activity-icon-box">
                    <SvgIcon
                      key={key}
                      className=""
                      icon={
                        data && data.attributes.svg_icon.data.attributes.SVGPath
                      }
                      viewBox={
                        data && data.attributes.svg_icon.data.attributes.viewbox
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="atoll-facts-info-grid-content-wrapper clearbg">
          <AtollInfoWidget
            population={island?.data.attributes.Population}
            guesthouses={
              island?.data.attributes.properties.data.filter(
                (data) => data.attributes.Accomodation_Type === "Guesthouse"
              ).length
            }
            homestays={
              island?.data.attributes.properties.data.filter(
                (data) => data.attributes.Accomodation_Type === "Homestay"
              ).length
            }
            activities={island?.data.attributes.activities.data.length}
            islandsInAtoll={20}
            restaurants={island?.data.attributes.Restaurants}
          />
          <PopularTags />
        </div>
        {/* <div className="atoll-facts-info-grid-column">
          <div className="atoll-facts-info-grid-content-wrapper center-to-div-flex">
            <MiniMap className="mini-map" miniAtollClickHandler={miniAtollClickHandler}/>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AtollFacts;
