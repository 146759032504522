import React from "react";
import { Route, Routes } from "react-router-dom";
import Welcome from "./components/Global/Welcome";
import Home from "./pages/Home/Home";
import Atoll from "./pages/Atoll/Atoll";
import ScrollToTop from "./components/Global/ScrollToTop";
import Island from "./pages/Island/Island";
import Data from "./data.json";
import Property from "./pages/Property/Property";
import Dashboard from "./pages/Dashboard/Dashboard";
import DashboardBookings from "./pages/Dashboard/DashboardBookings";
import DashboardQuotations from "./pages/Dashboard/DashboardQuotations";
import Activity from "./pages/Activity/Activity";
import NotFound from "./pages/NotFound/NotFound";
import Login from "./pages/Login/Login";
import WithNav from "./components/Global/NavBar/WithNav";
import WithoutNav from "./components/Global/NavBar/WithoutNav";
import DashboardProperties from "./pages/Dashboard/DashboardProperties";
import DashboardIslands from "./pages/Dashboard/DashboardIslands";
import DashboardLayout from "./components/Global/NavBar/DashboardLayout";
import DashboardPropertyEdit from "./components/DashboardComponents/DashboardPropertiesComponents/DashboardPropertyEdit";
import DashboardBookingsEdit from "./components/DashboardComponents/DashboardBookingsComponents/DashboardBookingsEdit";
import DashboardQuotationEdit from "./components/DashboardComponents/DashboardBookingsComponents/DashboardQuotationEdit";
import Booking from "./pages/Booking/Booking";
import Destinations from "./pages/Destinations/Destinations";
import ThankYou from "./pages/Booking/Thankyou";
import Results from "./pages/Results/Results";
import Packages from "./pages/Packages/Packages";
import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import Terms from "./pages/Policies/Terms";
//  console.log(Data);

const App = () => {
  return (
    <div>
      <ScrollToTop>
        <Routes>
          <Route element={<WithoutNav />}>
            <Route path="/login" element={<Login />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/thankyou" element={<ThankYou />} />
          </Route>
          <Route element={<DashboardLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/islands" element={<DashboardIslands />} />
            <Route
              path="/dashboard/properties"
              element={<DashboardProperties />}
            />
            <Route
              path="/dashboard/properties/edit/:propertyid"
              element={<DashboardPropertyEdit />}
            />
            <Route
              path="/dashboard/bookings/:bookingid"
              element={<DashboardBookingsEdit />}
            />
            <Route
              path="/dashboard/quotes/:quoteid"
              element={<DashboardQuotationEdit />}
            />
            <Route path="/dashboard/bookings" element={<DashboardBookings />} />
            <Route path="/dashboard/quotes" element={<DashboardQuotations />} />
          </Route>
          <Route element={<WithNav />}>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home items={Data} />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/atoll/:atollCode" element={<Atoll />} />
            <Route path="/island/:atollCode/:islandName" element={<Island />} />
            <Route path="/property/:propertyName" element={<Property />} />
            <Route path="/activities" element={<Activity />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/destinations" element={<Destinations />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<Terms />} />
            <Route path="/results/:query" element={<Results />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </div>
  );
};

export default App;
