import React, { useEffect, useState } from "react";
import "./Home.css";
import Hero from "../../components/HomeComponents/HeroSection/Hero";
import Tutorial from "../../components/HomeComponents/Tutorial/Tutorial";
import MainMap from "../../components/HomeComponents/MainMap/MainMap";
import QuickBooking from "../../components/HomeComponents/QuickBooking/QuickBooking";
import { getHomePage, getAllAtolls } from "../../components/Global/Api";
import Loading from "../../components/Global/Loading/Loading";

const Home = (props) => {
  const [homepage, setHomePage] = useState(0);
  const [atolls, setAtolls] = useState(0);
  const fetchHomePage = async () => {
    try {
      const { data, status } = await getHomePage();
      if (status === 200) {
        // console.log(data.data.attributes);
        setHomePage(data.data.attributes);
        // console.log(homepage);
        
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAtolls = async () => {
    try {
      const { data, status } = await getAllAtolls();
      if (status === 200) {
        console.log(data.data);
        setAtolls(data.data);
        
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchHomePage();
    fetchAtolls();
    // console.log(homepage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (homepage === 0) return (<Loading/>);
  return (
    <div>
      <Hero background={homepage.background.data} heading={homepage.Heading} paragraph={homepage.Paragraph} />
      <Tutorial />
      <MainMap atolls={atolls} data={props.items} />
      <QuickBooking />
    </div>
  );
};

export default Home;
