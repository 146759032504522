import React, { useEffect, useState } from "react";
import PropertyActivitiesSelect from "../../components/PropertyComponents/PropertyActivitiesSelect/PropertyActivitiesSelect";
import PropertyBooking from "../../components/PropertyComponents/PropertyBooking/PropertyBooking";
import PropertyHeader from "../../components/PropertyComponents/PropertyHeader/PropertyHeader";
import PropertyRelatedGuesthouses from "../../components/PropertyComponents/PropertyRelatedGuesthouses/PropertyRelatedGuesthouses";
import PropertyRelatedHomeStays from "../../components/PropertyComponents/PropertyRelatedGuesthouses/PropertyRelatedHomestays";
import { getProperty } from "../../components/Global/Api";
import { useParams, useNavigate } from "react-router-dom";
import "./Property.css";

const Property = () => {
  let navigate = useNavigate();

  const [property, setProperty] = useState(0);
  const [propertyID, setPropertyID] = useState(0);
  const { propertyName } = useParams();
  const [activities, setActivities] = useState([]);

  const handleChange = (event) => {
    var updatedList = [...activities];
    if (event.target.checked) {
      updatedList = [...activities, event.target.value];
    } else {
      updatedList.splice(activities.indexOf(event.target.value), 1);
    }
    setActivities(updatedList);
  };
  const fetchProperty = async () => {
    try {
      const { data, status } = await getProperty(propertyName);
      if (status === 200) {
        console.log(data.data[0].attributes);
        setProperty(data.data[0].attributes);
        setPropertyID(data.data[0].id);
      }
    } catch (error) {
      console.log(error);
      navigate("..`/404");
    }
  };
  useEffect(() => {
    console.log(activities);
  }, [activities]);
  useEffect(() => {
    fetchProperty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <PropertyHeader
        name={property.Name}
        description={property.Description}
        type={property.Accomodation_Type}
        amenities={property.amenities}
        rating={property.Rating}
        property={property}
      />
      <PropertyRelatedGuesthouses property={property} />
      <PropertyRelatedHomeStays property={property} />
      <PropertyActivitiesSelect
        property={property}
        handleChange={handleChange}
      />
      <PropertyBooking
        property={property}
        propertyID={propertyID}
        activities={activities}
      />
    </div>
  );
};

export default Property;
