import * as React from "react"

const ScrollIndicator = (props) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      className="cls-1"
      d="M249.999 500C141.97 500 54.086 412.11 54.086 304.084V195.916C54.086 87.888 141.971 0 249.999 0s195.916 87.888 195.916 195.916v108.168C445.915 412.11 358.025 500 249.999 500Zm0-479.876c-96.93 0-175.79 78.86-175.79 175.792v108.168c0 96.93 78.86 175.787 175.79 175.787s175.786-78.857 175.786-175.787V195.916c0-96.932-78.857-175.792-175.786-175.792Zm0 309.411-98.314-98.31 14.229-14.229 84.085 84.083 84.085-84.083 14.228 14.229L250 329.535Z"
    />
  </svg>
)

export default ScrollIndicator
