import React from "react";
import "./FeaturedSlider.css";
import { Carousel } from "react-responsive-carousel";
import FeaturedSlides from "./FeaturedSlides";

const FeaturedSlider = () => {
  return (
    <div >
      <Carousel
        autoPlay
        showThumbs={false}
        swipeable={true}
        emulateTouch={true}
        infiniteLoop={true}
        showStatus={false}
        showIndicators={true}
        showArrows={false}
        className="featuredSlider_container"
      >
        <FeaturedSlides />
        <FeaturedSlides />
        <FeaturedSlides />

        
      </Carousel>{" "}
    </div>
  );
};

export default FeaturedSlider;
