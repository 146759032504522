import GoogleMapReact from "google-map-react";
import "./GoogleMap.css";

const GoogleMap = (props) => {
  if (typeof props.latitude === "undefined") return null;

  const handleApiLoaded = (map, maps) => {
    map.mapTypeControl = true;
    map.mapTypeId = "terrain";
    map.styles = [
      { elementType: "geometry", stylers: [{ color: "#ffffff" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#06363A" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#ffffff" }] },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#0FFCB1" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#06363A" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#0070B8" }],
      },
    ]
  };
  return (
    <div className="map-container">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC_yHGenlyfQD8fFV3VemzN5JreAYNm0k0" }}
        center={{ lat: props.latitude, lng: props.longitude }}
        zoom={props.zoom}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
  
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
