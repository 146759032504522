import React from "react";
import "./AtollFeaturedProperty.css";
import IconGuestHouse from "../../../Assets/SVG/IconGuesthouse";
import IconHomeStay from "../../../Assets/SVG/IconHomeStay";
import { Link } from "react-router-dom";

const AtollFeaturedProperty = (props) => {
  if (props.property === undefined) return null;
  return (
    <Link to={"/property/" + props.property.Name}>
    <div className="atoll-single-property">
      {(props.property.Accomodation_Type === "Guesthouse") ? (<div className="atoll-single-property-icon">
        <IconGuestHouse />
      </div>) : (<div className="atoll-single-property-icon">
        <IconHomeStay />
      </div>)}  

      <div
        className="atoll-single-property-image"
        style={{
          backgroundImage:
            "url(" + props.property.Featured_Image.data.attributes.url + ")",
        }}
      ></div>
      <div className="atoll-single-property-details">
        <h3 className="atoll-single-property-name">
          {props.property.Name}
        </h3>
        <h4 className="atoll-single-property-type">{props.property.Accomodation_Type}</h4>
        <ul className="atoll-single-property-features">
          <li>6 Rooms</li>
          <li>Restaurants</li>
          <li>In-house Activities</li>
        </ul>
      </div>
    </div>
    </Link>
  );
};

export default AtollFeaturedProperty;
