import React from "react";
import "./ActivityHero.css";
// import ActivityHeroSlider from "./ActivityHeroSlider";

const ActivityHero = () => {
  return (
    <div className="activity-hero">
      <h1 className="britania">
        Maldivian Activities. <br></br>Local Lifestyle
      </h1>
      {/* <ActivityHeroSlider/> */}
    </div>
  );
};

export default ActivityHero;
