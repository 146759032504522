import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import IconGuestHouse from "../../../Assets/SVG/IconGuesthouse";
import IconHomeStay from "../../../Assets/SVG/IconHomeStay";
import RightChevron from "../../../Assets/SVG/RightChevron";
import "./AtollFeaturedProperties.css";
import AtollFeaturedProperty from "./AtollFeaturedProperty";
import InfoModal from "./InfoModal";

const AtollFeaturedproperties = (props) => {
  const [propertyAtoll, setpropertyAtoll] = useState(undefined);
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const closeIcon = <FontAwesomeIcon icon={faTimesCircle} />;
  useEffect(() => {
    setpropertyAtoll(props.atoll);
  }, [props.atoll]);

  useEffect(() => {
    if (propertyAtoll !== undefined)
      console.log(propertyAtoll.attributes.islands);
  }, [propertyAtoll]);

  if (propertyAtoll === undefined) return null;
  return (
    <div className="featured-properties">
      <h1 className="section-h1">
        Featured Guesthouses in {propertyAtoll.attributes.Name}
      </h1>
      <p className="section-p">
        The beauty of the Maldives is not only above the water. The Maldives is
        home to about five percent of the planet’s reefs that comes with an
        explosion of color contributed by soft and hard corals that form them.
        The reefs are home to a thousand species of fish. Lured by the rich
        nutrients that flow in with the currents, large pelagic fishes such as
        manta rays and whale sharks also make the Maldives their home.
      </p>

      <div className="featured-property-grid">
        {propertyAtoll &&
          propertyAtoll.attributes.islands.data.map((outerElement) =>
            outerElement.attributes.properties.data.map((innerElement) => (
              <AtollFeaturedProperty
                key={innerElement.id}
                property={innerElement.attributes}
              />
            ))
          )}
      </div>

      <div
        className="BlueButton property-type-modal-button"
        onClick={onOpenModal}
      >
        <span>
          <IconGuestHouse />
        </span>
        <span>
          <IconHomeStay />
        </span>
        <span>?</span>
      </div>
      <Link
        to="/booking"
        state={{
          atoll: propertyAtoll,
        }}
      >
        <div className="BlueButton">
          Make a quick trip to {propertyAtoll.attributes.Name} Atoll{" "}
          <RightChevron />{" "}
        </div>
      </Link>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        closeIcon={closeIcon}
        classNames={{
          modal: "infoModal",
        }}
      >
        <InfoModal />
      </Modal>
    </div>
  );
};

export default AtollFeaturedproperties;
