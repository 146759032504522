import React from "react";
import IslandActivitiesSlide from "./IslandActivitiesSlide";
import "./IslandActivitiesSlider.css";
import { Carousel } from "react-responsive-carousel";
import ScrollIndicator from "../../../Assets/SVG/ScrollIndicator";

const IslandActivitiesSlider = (props) => {
  if (props.activities === undefined) return null;

  return (
    <div>
      <Carousel
        showThumbs={false}
        swipeable={true}
        emulateTouch={true}
        infiniteLoop={true}
        showStatus={false}
        showIndicators={true}
        showArrows={false}
        stopOnHover={true}
        interval={2500}
        className="island-activities-slider"
        autoPlay={true}
      >
        {props.activities.data.length > 0 &&
          props.activities.data.map((data, key) => (
            <IslandActivitiesSlide key={key} activity={data.attributes} />
          ))}
      </Carousel>

      <div className="scroll-indicator">
        <ScrollIndicator />
        <span>
          Choose a guesthouse<br></br>to make a reservation
        </span>
      </div>
    </div>
  );
};

export default IslandActivitiesSlider;
