import React, { useEffect, useState } from "react";
import "./AtollFeaturedActivities.css";
import AtollFeaturedActivity from "./AtollFeaturedActivity";

const AtollFeaturedActivities = (props) => {
  const [activityAtoll, setActivityAtoll] = useState();

  useEffect(() => {
    setActivityAtoll(props.atoll);
  }, [props.atoll]);

  useEffect(() => {}, [activityAtoll]);

  if (activityAtoll === undefined) return null;
  return (
    <div className="atoll-featured-activities">
      <h1 className="section-h1">
        Featured Activities in {activityAtoll.Name}
      </h1>
      <p className="section-p">
        The beauty of the Maldives is not only above the water. The Maldives is
        home to about five percent of the planet’s reefs that comes with an
        explosion of color contributed by soft and hard corals that form them.
        The reefs are home to a thousand species of fish. Lured by the rich
        nutrients that flow in with the currents, large pelagic fishes such as
        manta rays and whale sharks also make the Maldives their home.
      </p>
      <div className="atoll-featured-activity-grid">
        {activityAtoll.activities.data.length > 0 &&
          activityAtoll.activities.data.map((data, key) => (
            <AtollFeaturedActivity key={key} activity={data.attributes} />
          ))}
      </div>
    </div>
  );
};

export default AtollFeaturedActivities;
