import React, { useState, useEffect } from "react";

const SvgIcon = (props) => {
  const [paths, setPaths] = useState([]);

  useEffect(() => {
    setPaths(props.icon);
  }, [props.icon]);
  console.log(props.viewBox);
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={String(props.viewBox)}
      width="1em"
      height="1em"
      className={props.className}
    >
      {paths.map((data, key) => (
        <path className="cls-1" key={key} d={data.D} />
      ))}
    </svg>
  );
};

export default SvgIcon;
