import * as React from "react"

const WidgetPopulation = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      className="cls-1"
      d="M339.377 258.171c0 22.146.03 44.291-.01 66.437-.03 17.285-9.003 27.83-26.046 30.74-1.1.188-2.206.338-3.31.5-9.08 1.333-11.494 4.013-11.501 13.169-.03 37.033-.012 74.066-.016 111.099 0 3.163.104 6.334-.073 9.488-.342 6.05-3.489 9.37-9.481 9.395-25.867.11-51.735.12-77.601.003-6.98-.031-9.726-3.603-9.732-11.993-.03-37.778-.013-75.555-.014-113.332 0-15.388-.404-15.864-15.732-18.517-15.9-2.752-25.11-13.56-25.13-29.808-.053-43.174-.074-86.349.046-129.522.017-5.728.22-11.622 1.55-17.148 5.227-21.744 24.78-37.1 47.14-37.229 26.797-.153 53.595-.146 80.393-.014 27.844.137 49.34 21.766 49.492 49.738.122 22.33.025 44.663.025 66.994Z"
    />
    <path
      className="cls-1"
      d="M331.032 369.41c13.442-7.108 22.468-17.71 25.535-32.718.884-4.326 1.003-8.864 1.01-13.306.071-42.606.043-85.212.043-127.817v-6.955c2.099-.157 3.704-.382 5.31-.383 23.256-.022 46.512-.079 69.769.002 26.6.093 45.22 18.778 45.25 45.38.044 37.768.023 75.537.007 113.305-.008 17.615-9.363 27.694-26.882 29.043-5.942.458-8.347 3.227-8.363 10.142-.054 24.372-.022 48.745-.024 73.118-.001 9.86.085 19.723-.036 29.582-.098 7.998-3.313 11.149-11.252 11.165-21.21.044-42.42.04-63.63.002-8.2-.015-11.487-3.294-11.496-11.55-.038-33.675-.012-67.35-.02-101.026-.002-9-1.659-10.37-10.342-11.65-5.16-.76-10.109-2.965-15.152-4.52l.273-1.815ZM142.47 187.877V317.15c0 28.381 3.452 35.428 27.02 53.312-5.786 1.688-10.498 3.702-15.39 4.346-8.157 1.074-10.258 2.899-10.261 11.292-.013 33.312 0 66.625-.009 99.937-.002 10.234-2.726 13.02-12.772 13.028-20.472.018-40.943.023-61.414-.003-9.435-.012-12.218-2.801-12.228-12.386-.032-33.127-.006-66.253-.017-99.38-.004-9.956-1.563-11.621-11.423-12.372-12.785-.972-23.65-11.727-23.72-24.625-.22-40.755-.428-81.516.105-122.266.279-21.358 19.2-40.008 40.87-40.547 25.667-.638 51.36-.165 77.042-.153.521 0 1.042.25 2.196.545ZM309.51 60.16c-.516 32.73-27.58 59.21-59.987 58.689-32.802-.528-59.356-27.472-58.919-59.785.46-33.964 26.132-59.346 59.734-59.062 34.135.29 59.704 26.285 59.171 60.157ZM399.041 165.147c-30.29-.296-54.913-25.13-54.46-54.923.483-31.637 24.367-55.08 55.649-54.617 31.316.463 54.642 24.607 54.202 56.102-.414 29.65-25.376 53.732-55.39 53.438ZM100.7 54.971c31.514.032 55.096 23.83 54.81 55.312-.271 29.827-24.706 54.074-54.695 54.277-29.87.201-55.16-24.755-55.17-54.44-.009-31.554 23.577-55.18 55.054-55.149Z"
    />
  </svg>
)

export default WidgetPopulation
