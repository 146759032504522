import * as React from "react"

const WidgetRestaurants = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width="1em"
    height="1em"
    {...props}
  >
    
    <path
      className="cls-1"
      d="M442.43 0c3.367 2.142 7.066 3.908 10.02 6.517 4.06 3.588 5.816 8.53 5.814 14-.016 42.959.423 85.924-.202 128.874-.507 34.785-25.232 65.6-59.366 75.934-10.93 3.309-13.15 6.313-13.15 17.825-.003 74.365.016 148.73-.015 223.095-.008 17.268-9.924 29.922-25.79 33.181-17.162 3.526-35.094-9.91-36.5-27.398-.208-2.59-.19-5.203-.19-7.805-.01-73.551-.008-147.103-.009-220.654 0-11.663-2.374-14.919-13.35-18.328-36.23-11.25-59.62-42.898-59.643-80.776-.026-39.542.248-79.087-.152-118.625-.13-12.857 4.372-21.623 16.748-25.84h8.789c12.521 4.523 16.657 13.721 16.505 26.608-.423 35.639-.169 71.286-.127 106.93.01 8.599 3.597 12.117 12.191 12.206 5.208.054 10.417.02 15.625.01 10.52-.024 13.66-3.15 13.664-13.69.012-35.156.285-70.315-.142-105.465C332.993 13.71 337.133 4.518 349.655 0h8.789c12.346 4.218 16.895 12.961 16.745 25.805-.414 35.59-.15 71.187-.139 106.781.003 9.69 3.471 13.123 13.25 13.165 4.713.02 9.426.01 14.14.003 11.27-.013 14.352-3.14 14.353-14.592.005-36.082.05-72.164-.04-108.246-.016-6.474 1.25-12.423 6.237-16.765 2.85-2.481 6.425-4.128 9.674-6.151h9.766ZM131.876 0c40.282 8.817 58.533 38.757 69.12 74.502 12.746 43.04 9.877 85.549-9.837 126.342-6.144 12.715-14.53 23.884-25.348 33.09-6.608 5.622-9.633 12.525-9.613 21.374.165 70.627.111 141.255.078 211.883-.008 17.787-12.821 31.911-29.533 32.769-17.444.895-32.687-13.215-32.897-30.822-.21-17.573-.056-35.15-.057-52.726-.001-54.192-.043-108.383.051-162.575.013-7.145-2.199-13.364-7.467-17.871-22.958-19.642-35.163-45.253-40.856-74.176-7.794-39.593-4.414-78.096 14.301-114.4 9.76-18.931 23.197-34.598 43.498-42.97 4.76-1.963 9.914-2.972 14.887-4.42h13.673Z"
    />
  </svg>
)

export default WidgetRestaurants
