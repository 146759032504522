import React from "react";
import "./PropertyInfo.css";
import ReactStars from "react-rating-stars-component";
import RightChevron from "../../../Assets/SVG/RightChevron";
import PropertyReviews from "./PropertyReviews";
import SvgIcon from "../../Global/SvgIcon";

const PropertyInfo = (props) => {
  if (typeof props.amenities === "undefined") return null;
  console.log(props.properties);
  return (
    <div className="property-info">
      <h1>{props.name}</h1>
      <ReactStars
        size={24}
        color={"#ccc"}
        value={props.rating}
        edit={false}
        isHalf={true}
      />
      <h4>{props.type}</h4>
      <div className="property-info-rating">
        <h4>
          {
            props.property.island.data.attributes.atoll.data.attributes
              .AtollCode
          }
          . {props.property.island.data.attributes.Name}
        </h4>
      </div>

      <div className="property-info-offers">
        {props.amenities.data.length > 0 &&
          props.amenities.data.slice(0, 4).map((data, key) => (
            <div
              title={data.attributes.Name}
              key={key}
              className="property-info-offers-icon-wrapper"
            >
              <SvgIcon
                className=""
                icon={data.attributes.svg_icon.data.attributes.SVGPath}
                viewBox={data.attributes.svg_icon.data.attributes.viewbox}
              />
            </div>
          ))}
        <span>
          Details <RightChevron />
        </span>
      </div>
      <div className="property-info-offers-description">
        <p>{props.description}</p>
      </div>
      <PropertyReviews />
    </div>
  );
};

export default PropertyInfo;
