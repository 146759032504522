import React from "react";
import { Carousel } from "@trendyol-js/react-carousel";
import PropertyRelatedGuesthousesSlide from "./PropertyRelatedGuesthousesSlide";
import "./PropertyRelatedGuesthousesSlider.css";

const PropertyRelatedGuesthousesSlider = () => {
  const w = window.innerWidth;

  console.log(w);
  return (
    <Carousel
      show={window.innerWidth > 600 ? 4 : 2}
      slide={window.innerWidth > 600 ? 4 : 2}
      swiping={true}
      infinite={true}
    >
      <PropertyRelatedGuesthousesSlide />
      <PropertyRelatedGuesthousesSlide />
      <PropertyRelatedGuesthousesSlide />
      <PropertyRelatedGuesthousesSlide />
      <PropertyRelatedGuesthousesSlide />
      <PropertyRelatedGuesthousesSlide />
      <PropertyRelatedGuesthousesSlide />
      <PropertyRelatedGuesthousesSlide />
    </Carousel>
  );
};

export default PropertyRelatedGuesthousesSlider;
