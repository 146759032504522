import React, { useEffect, useState } from "react";
import "./DashboardProperties.css";
import DashboardPropertyCard from "../../components/DashboardComponents/DashboardPropertyCard/DashboardPropertyCard";
import { managersProperties } from "../../components/Global/Api";
import Loading from "../../components/Global/Loading/Loading";
const DashboardProperties = () => {
  const [properties, setProperties] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchProperties = async () => {
    try {
      const { data } = await managersProperties(
        sessionStorage.getItem("token")
      );
      console.log(data.properties);
      setProperties(data.properties);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dashboard-properties-wrapper">
      <h1 className="dashboard-properties-title">Properties</h1>
      <h2 className="dashboard-properties-subtitle">
        Properties Managed by {"username"}
      </h2>
      <div className="dashboard-properties">
        {/* {properties.slice(0, properties.length).map((data, key) => (
        <DashboardPropertyCard key={key} name={data.Name} description={data.Description}/>
      ))} */}
        {console.log(loading)}
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div className="dashboard-grid">
            {properties.slice(0, properties.length).map((data, key) => (
              <DashboardPropertyCard
                key={key}
                id={data.id}
                image={data.Featured_Image}
                images={data.Images}
                name={data.Name}
                type={data.Accomodation_Type}
                rating={data.Rating}
                description={data.Description}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardProperties;
