import React, { useEffect, useState } from "react";
// import Loading from "../../components/Global/Loading/Loading";
import { getPackages } from "../../components/Global/Api";
import PackagesDetails from "../../components/PackagesComponents/PackagesDetails";
import PackagesHero from "../../components/PackagesComponents/PackagesHero";
import "./Packages.css";

const Packages = () => {
  const [packages, setPackages] = useState(0);

  const fetchPackages = async () => {
    try {
      const { data, status } = await getPackages();
      if (status === 200) {
        console.log(data);
        setPackages(data);
      }
    } catch (error) {
      console.log(error);
      setPackages("../404");
    }
  };
  useEffect(() => {
    fetchPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <PackagesHero />
      <PackagesDetails packages={packages} />
    </div>
  );
};

export default Packages;
