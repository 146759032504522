import React, { useEffect, useState } from "react";
import "./AtollFeaturedIslandSlider.css";
import AtollFeaturedIslandSlide from "./AtollFeaturedIslandSlide";
import { Carousel } from "react-responsive-carousel";

const AtollFeaturedIslandSlider = (props) => {
  const [ islands, setIslands] = useState();
  
  useEffect (() => {
    setIslands(props.islands);

  }, [props.islands]);


  if (props.islands === undefined || props.islands.data.length === 0) return null;
  return (
    <div>
      <Carousel
        autoPlay
        showThumbs={false}
        swipeable={true}
        emulateTouch={true}
        infiniteLoop={true}
        showStatus={false}
        showIndicators={true}
        showArrows={false}
        stopOnHover={true}
        interval={3500}
        className="featured-island-slider"
      >
       {islands && islands.data.map((data, key) => (
            <AtollFeaturedIslandSlide key={key} island={data.attributes} atoll={props.atoll} />
          ))}
      </Carousel>
    </div>
  );
};

export default AtollFeaturedIslandSlider;
