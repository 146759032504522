import * as React from "react"

const BookingFour = ({ title, titleId, ...props }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width="1em"
    height="1em"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M405.832 500H271.715v-99.23H17.212v-91.86L246.814 0h148.604L188.842 284.21h86.25v-88.442h130.74v88.441h76.956v116.56h-76.956V500Zm-106.669-27.448h79.221v-99.23h76.956v-61.665h-76.956v-88.441H302.54v88.441H134.965L341.54 27.447h-80.923L44.66 317.998v55.324h254.503v99.231Z"
      style={{
        fill: "#06363a",
      }}
    />
  </svg>
)

export default BookingFour
