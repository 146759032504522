import React, { useEffect, useState } from "react";
import AtollFeaturedActivities from "../../components/AtollComponents/AtollFeaturedActivities/AtollFeaturedActivities";
import AtollFeaturedIslands from "../../components/AtollComponents/AtollFeaturedIslands/AtollFeaturedIslands";
import AtollFeaturedproperties from "../../components/AtollComponents/AtollFeaturedProperties/AtollFeaturedproperties";
import AtollHeader from "../../components/AtollComponents/AtollHeader/AtollHeader";
import { getAtoll } from "../../components/Global/Api";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../components/Global/Loading/Loading";

import "./Atoll.css";

const Atoll = (props) => {
  const [atoll, setAtoll] = useState(0);
  const { atollCode } = useParams();
  let navigate = useNavigate();

  const fetchAtolls = async () => {
    try {
      const { data, status } = await getAtoll(atollCode);
      if (status === 200) {
        console.log(data);
        setAtoll(data.data[0]);
      }
    } catch (error) {
      console.log(error);
      navigate("../404");
    }
  };

  useEffect(() => {
    document.getElementById(atollCode);
    let elements = document.getElementsByName("single-atoll-on-mini-map");
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute("class", "");
      let curSelAtoll = document.getElementById(atollCode);
      curSelAtoll.setAttribute("class", "active");
    }
  }, [atoll, atollCode]);

  useEffect(() => {
    fetchAtolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atollCode]);

  if (atoll === 0 || atoll === undefined) return <Loading />;
  return (
    <div>
      <AtollHeader
        atoll={atoll.attributes}
        cover={
          atoll.attributes.Featured_Image.data !== null &&
          atoll.attributes.Featured_Image.data.attributes.url
        }
      />
      <AtollFeaturedIslands atoll={atoll} islands={atoll.attributes.islands} />
      <AtollFeaturedActivities atoll={atoll.attributes} />
      <AtollFeaturedproperties atoll={atoll} />
    </div>
  );
};

export default Atoll;
