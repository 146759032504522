import React from "react";
import PackageGrid from "./PackageGrid";
import "./PackagesDetails.css";

const PackagesDetails = (props) => {
  return (
    <div>
      <PackageGrid packages={props.packages} />
    </div>
  );
};

export default PackagesDetails;
