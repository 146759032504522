import React from "react";
import Gallery from "../../Global/Gallery/Gallery";
import GoogleMap from "../../Global/GoogleMap/GoogleMap";
import "./IslandHeader.css";
import IslandInfo from "./IslandInfo";
import IslandTutorialIMG from "../../../Assets/Images/islandtutorial.png";

const IslandHeader = (props) => {
  return (
    <div className="island-header">
      <div className="island-header-col-1">
        <h1>{props.name}</h1>
        <p>{props.description}</p>
        <Gallery
          qty={4}
          wrapperStyle={"atoll-gallery-wrapper"}
          itemStyle={"atoll-image"}
          images={props.island.Images}
        />
      </div>
      <div className="island-header-col-2">
        {/* <IslandTutorial /> */}
        <div className="island-tutorial-image">
          <img src={IslandTutorialIMG} alt="" />
        </div>

        <GoogleMap
          latitude={props.latitude}
          longitude={props.longitude}
          zoom={props.zoom}
        />
        <IslandInfo
          name={props.name}
          population={props.population}
          activities={props.island.activities}
        />
      </div>
    </div>
  );
};

export default IslandHeader;
