import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import PropertySingleReview from './PropertySingleReview';
import "./PropertyReviews.css";

const PropertyReviews = () => {
  return (
    <Carousel
        autoPlay
        showThumbs={false}
        swipeable={true}
        emulateTouch={true}
        infiniteLoop={true}
        showStatus={false}
        showIndicators={true}
        showArrows={true}
        stopOnHover={true}
        interval={2500}
        className="review-slider"
      >
          <PropertySingleReview/>
          <PropertySingleReview/>
          <PropertySingleReview/>
        
      </Carousel>
  )
}

export default PropertyReviews
