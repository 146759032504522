import React, { useState, useEffect } from "react";
import "./MainMap.css";
import Map from "../../../Assets/SVG/Map";
import MapDetails from "./MapDetails";
import { Link } from "react-router-dom";
import DropDown from "../../Global/DropDown";
import RightChevron from "../../../Assets/SVG/RightChevron";

const MainMap = (props) => {
  const defaultMapAtoll = props.atolls[0];
  const [mapAtoll, setMapAtoll] = useState();
  const [atollDropdown, setAtollDropdown] = useState();
  const [atollDropdownSel, setAtollDropdownSel] = useState(props.atolls[0]);
  const [islandDropdown, setIslandDropdown] = useState();
  const [islandDropdownSel, setIslandDropdownSel] = useState(null);

  //  FUNCTIONS PASSED TO PROPS TO HANDLE DROPDOWN OPTION
  const handleAtollSelect = (id, code) => {
    setAtollDropdownSel(props.atolls[id - 1]);
    atollClickHandler(code, id);
  };

  const handleIslandSelect = (id, code) => {
    setIslandDropdownSel(mapAtoll.attributes.islands.data[id]);
  };

  // useEffect(() => {
  //   console.log(islandDropdownSel?.attributes?.Description);
  // }, [islandDropdownSel]);

  useEffect(() => {
    setMapAtoll(defaultMapAtoll);
  }, [defaultMapAtoll]);

  // DROPDOWN ATOLL OPTIONS GENERATOR
  useEffect(() => {
    let optionVals = undefined;
    let optionList = undefined;
    if (props.atolls !== undefined) optionList = Array.from(props.atolls);
    // console.log(optionList);
    optionVals = optionList.map((option, key) => {
      return {
        Name: option.attributes.Name + " Atoll",
        id: option.id,
        code: option.attributes.AtollCode,
      };
    });
    setAtollDropdown(optionVals);
    // console.log(optionVals);
  }, [props.atolls]);

  useEffect(() => {
    if (atollDropdownSel !== undefined)
      // console.log(atollDropdownSel.attributes.Name + " Atoll Selected");
      setMapAtoll(atollDropdownSel);
  }, [atollDropdownSel]);
  // DROPDOWN ATOLLS OPTIONS GENERATOR END

  // DROPDOWN ISLAND OPTIONS GENERATOR
  useEffect(() => {
    setIslandDropdown();
    let optionVals = undefined;
    let optionList = undefined;
    if (mapAtoll !== undefined && mapAtoll.attributes.islands.data.length > 0) {
      optionList = Array.from(mapAtoll.attributes.islands.data);
      // console.log(optionList);
      optionVals = optionList.map((option, key) => {
        return {
          Name: option.attributes.Name,
          id: key,
          code: null,
        };
      });
      // console.log(mapAtoll?.attributes.AtollCode + ". " + optionVals[0].Name);
      setIslandDropdown(optionVals);
    }
  }, [mapAtoll]);

  // useEffect(() => {
  //   console.log(islandDropdown);
  // }, [islandDropdown]);
  // DROPDOWN ISLANDS OPTIONS GENERATOR END

  function atollClickHandler(e, i) {
    const newMapAtoll = props.atolls[i - 1];
    setMapAtoll(newMapAtoll);
    setIslandDropdownSel(undefined);
    document.getElementById("detailsStart");
    let elements = document.getElementsByName("single-atoll-on-map");
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute("class", "");
      let curSelAtoll = document.getElementById(e);
      curSelAtoll.setAttribute("class", "active-atoll");
    }
  }
  if (defaultMapAtoll === undefined) return null;
  return (
    <div>
      <div className="mini-select">
        <div className="mini-select-grid-1">
          <DropDown
            options={atollDropdown}
            handleSelect={handleAtollSelect}
            default={mapAtoll?.attributes.Name + " Atoll"}
          />
        </div>
        <div className="mini-select-grid-2">
          <DropDown
            options={islandDropdown}
            handleSelect={handleIslandSelect}
            default={islandDropdownSel?.attributes?.Name || "Choose Island"}
          />
        </div>
        <div className="mini-select-grid-3">
          {islandDropdownSel?.attributes !== undefined && (
            <Link
              className="BlueButton"
              to={
                "/island/" +
                mapAtoll.attributes.AtollCode +
                "/" +
                islandDropdownSel?.attributes?.Name
              }
            >
              Take Me There{" "}
              <RightChevron style={{ fill: " var(--darkblue)" }} />
            </Link>
          )}
        </div>
      </div>
      <div className="mainMap_Grid_Container">
        <div className="mainMap_Map_Wrapper">
          <Map className="mapsvg" atollClickHandler={atollClickHandler} />
        </div>
        <div className="mainMap_Details_Wrapper">
          <MapDetails displayatoll={mapAtoll} />
        </div>
      </div>
    </div>
  );
};

export default MainMap;
