import React from "react";
import AtollFeaturedActivity from "../AtollComponents/AtollFeaturedActivities/AtollFeaturedActivity";
import "./ActivityGrid.css";

const ActivityGrid = (props) => {
  if (typeof props.activities.data === "undefined") return null;
  // console.log(props.activities.data);
  return (
    <div className="activity-grid-container">
      <h1 className="britania">{props.heading}</h1>
      <p className="section-p">{props.paragraph}</p>

      <div className="activity-grid">
        {props.activities.data
          .slice(0, props.activities.data.length)
          .map((data, key) => (
            <AtollFeaturedActivity
            key={key} activity={data.attributes}
            />
          ))}
        {/* <AtollFeaturedActivity
          image={props.activities.data[0].attributes.Image}
        /> */}
      </div>
    </div>
  );
};

export default ActivityGrid;
