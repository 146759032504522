import React from "react";
import "./BookingTutorial.css";
import BookingActivities from "../../../Assets/SVG/BookingActivities";
import BookingBudget from "../../../Assets/SVG/BookingBudget";
import BookingGuests from "../../../Assets/SVG/BookingGuests";
import BookingInfo from "../../../Assets/SVG/BookingInfo";
import BookingOne from "../../../Assets/SVG/BookingOne";
import BookingTwo from "../../../Assets/SVG/BookingTwo";
import BookingThree from "../../../Assets/SVG/BookingThree";
import BookingFour from "../../../Assets/SVG/BookingFour";

const BookingTutorial = () => {
  return (
    <div className="quick-booking-wrapper">
      <div className="booking-header-wrapper">
        <h1>Make Your Quick Trip</h1>
        <p>
          The beauty of the Maldives is not only above the water. The Maldives
          is home to about five percent of the planet’s reefs that comes with an
          explosion of color contributed by soft and hard corals that form them.
        </p>
      </div>
      <div className="tutorial-icon-wrapper">
        <div className="tutorial-step">
          <BookingOne className="tutorial-number" />
          <div className="tutorial-icon-box">
            <BookingBudget className="tutorial-icon" />
          </div>
          <h4>Give us a budget</h4>
        </div>
        <div className="tutorial-mid">
          <span>+</span>
        </div>
        <div className="tutorial-step">
          <BookingTwo className="tutorial-number" />
          <div className="tutorial-icon-box">
            <BookingActivities className="tutorial-icon" />
          </div>
          <h4>Choose your activities</h4>
        </div>
        <div className="tutorial-mid">
          <span>+</span>
        </div>
        <div className="tutorial-step">
          <BookingThree className="tutorial-number" />
          <div className="tutorial-icon-box">
            <BookingGuests className="tutorial-icon" />
          </div>
          <h4>Select number of guests</h4>
        </div>
        <div className="tutorial-mid">
          <span>=</span>
        </div>
        <div className="tutorial-step">
          <BookingFour className="tutorial-number" />
          <div className="tutorial-icon-box">
            <BookingInfo className="tutorial-icon" />
          </div>
          <h4>Essential trip would be offered</h4>
        </div>
      </div>
    </div>
  );
};

export default BookingTutorial;
