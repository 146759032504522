import React, { useState, useEffect, useCallback } from "react";
import "./Hero.css";
import TagLine from "./TagLine";
import FeaturedSlider from "./FeaturedSlider";


const Hero = (props) => {
const [background, setBackground] = useState(props.background[0].attributes.url);

const shuffle = useCallback(() => {
  const max = props.background.length;
  const index = Math.floor(Math.random() * max);
  setBackground(props.background[index].attributes.url);
}, [props.background]);

useEffect(() => {
  const intervalID = setInterval(shuffle, 10000);
  return () => clearInterval(intervalID);
}, [shuffle])


// useEffect(() => {
//     const max = props.background.length;
//     const rand = Math.floor(Math.random() * max);;
//     console.log(rand)
//   setBackground(props.background[rand].attributes.url);
// }, [props.background]);
  
 
  
  return (
    <div className="hero" style={{
      backgroundImage:
        "url(" + background + ")",
    }}>
      <TagLine heading={props.heading} paragraph={props.paragraph} />
      <FeaturedSlider />
    </div>
  );
};

export default Hero;
