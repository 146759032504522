import React, { useState } from "react";
import { Link } from "react-router-dom";
import RightChevron from "../../Assets/SVG/RightChevron";
import SvgIcon from "../Global/SvgIcon";

import "./PopularActivity.css";

const PopularActivity = (props) => {
  const [selectedIsland, setSelectedIsland] = useState("");

  const handleIslandSelect = (island) => {
    console.log(island);
    setSelectedIsland(island);
  };

  // if (typeof props.image === "undefined") return null
  console.log(props.filter);
  if (props.activity.Name !== props.filter && props.filter !== "*") return null;
  return (
    <div className="popular-activity">
      <div
        className="popular-activity-image"
        style={{
          backgroundImage: "url(" + props.image.data.attributes.url + ")",
          // "url(https://images.unsplash.com/photo-1614440455073-bd7fa8669eb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
        }}
      >
        <div className="popular-activity-name">{props.activity.Name}</div>
        <div className="popular-activity-icon">
          <SvgIcon
            className=""
            icon={props.activity.svg_icon.data.attributes.SVGPath}
            viewBox={props.activity.svg_icon.data.attributes.viewbox}
          />
        </div>
      </div>
      <div className="popular-activity-details-wrapper">
        <div className="popular-activity-details">
          <h2>{props.name}</h2>
          <div className="islandtag">
            <h5>Click to choose island:</h5>
            {props.islands.data
              .slice(0, props.islands.data.length)
              .map((data, key) => (
                <button
                  key={key}
                  onClick={() => {
                    handleIslandSelect(data);
                  }}
                >
                  {data.attributes.atoll.data.attributes.AtollCode}.
                  {data.attributes.Name}
                </button>
              ))}
          </div>
          <p>{props.paragraph}</p>
        </div>
        {selectedIsland === "" ? (
          <div></div>
        ) : (
          <Link
            to="/booking"
            state={{
              activities: props.name,
              island: selectedIsland,
              atoll: selectedIsland.attributes.atoll.data,
            }}
          >
            <div className="BlueButton">
              Make a quick trip to {selectedIsland.attributes.Name}{" "}
              <RightChevron />{" "}
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default PopularActivity;
