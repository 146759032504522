import React from "react";
import ReactStars from "react-rating-stars-component";
import "./PropertySingleReview.css";

const PropertySingleReview = () => {
  return (
    <div className="reviews">
      <div>
        <h3>Reviews</h3>
        <p>
          The beauty of the Maldives is not only above the water. The Maldives
          is home to about five percent of the planet’s reefs that comes with an
          explosion of color contributed by soft and hard corals that form them.
          The reefs are home to a thousand species of fish. Lured by the rich
          nutrients that flow in with the currents, large pelagic fishes such as
          manta rays and whale sharks also make the Maldives their home.
        </p>
        <ReactStars
          size={24}
          color={"#6EAFCC"}
          activeColor="#ffffff"
          value={3}
          edit={false}
          isHalf={true}
        />
        <h5>Mac Miller - 2022</h5>
      </div>
    </div>
  );
};

export default PropertySingleReview;
