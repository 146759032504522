import {
  faUser,
  faUserGroup,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import RightChevron from "../../Assets/SVG/RightChevron";

import Gallery from "../Global/Gallery/Gallery";
import SvgIcon from "../Global/SvgIcon";
import "./SinglePackage.css";

const SinglePackage = (props) => {
  const singlePackage = props.package.attributes;
  return (
    <div className="package-wrapper">
      <div>
        <Gallery
          qty={7}
          wrapperStyle={"package-gallery-wrapper"}
          itemStyle={"package-image"}
          images={singlePackage.Images}
        />
        <div className="feature-wrapper">
          {singlePackage.package_features.data.map((feature, key) => (
            <div className="single-feature-wrapper" key={key}>
              <span className="feature-icon-wrapper">
                <SvgIcon
                  className=""
                  icon={feature.attributes.svg_icon.data.attributes.SVGPath}
                  viewBox={feature.attributes.svg_icon.data.attributes.viewbox}
                />
              </span>
              <span>{feature.attributes.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="package-details-column">
        <div className="package-icon-set">
          <span className="package-type">
            {singlePackage.Type === "couples" ? (
              <FontAwesomeIcon icon={faUserGroup} />
            ) : singlePackage.Type === "solo" ? (
              <FontAwesomeIcon icon={faUser} />
            ) : (
              <FontAwesomeIcon icon={faUsers} />
            )}
          </span>
          {singlePackage.activities.data.map((activity, key) => (
            <div className="package-single-activity" key={key}>
              <SvgIcon
                className=""
                icon={activity.attributes.svg_icon.data.attributes.SVGPath}
                viewBox={activity.attributes.svg_icon.data.attributes.viewbox}
              />
            </div>
          ))}
        </div>
        <h1 className="package-name">{singlePackage.Name}</h1>
        <p className="package-location">
          {singlePackage.island.data.attributes.atoll.data.attributes.AtollCode}
          . {singlePackage.island.data.attributes.Name}
        </p>
        {singlePackage.property.data ? (
          <p className="package-location">
            {singlePackage.property.data.attributes.Name}
          </p>
        ) : (
          ""
        )}

        <p className="package-description">{singlePackage.Description}</p>
        <Link
          to="/booking"
          state={{
            packageDeals: props.package,
            property: singlePackage.property.data,
            atoll: singlePackage.island.data.attributes.atoll.data,
            island: singlePackage.island.data,
          }}
        >
          <div className="BlueButton">
            I am interested
            <RightChevron />{" "}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SinglePackage;
