import React from "react";
import "./PropertyActivitiesSelect.css";

const PropertyActivitiesSelect = (props) => {
  if (props.property.island === undefined) return null;

  return (
    <div className="property-activities-select">
      <h3>
        ACTIVITIES IN{" "}
        {props.property && props.property.island.data.attributes.Name}
      </h3>
      <div className="property-activities-grid">
        {props.property.island.data.attributes.activities.data.length > 0 &&
          props.property.island.data.attributes.activities.data.map(
            (data, key) => (
              <label key={key} htmlFor={"checkbox" + key}>
                <div className="property-activities-grid-item">
                  <div
                    htmlFor={"checkbox" + key}
                    className="property-activities-grid-item-image"
                    style={{
                      backgroundImage:
                        "url(" +
                        data.attributes.Image.data.attributes.url +
                        ")",
                    }}
                  >
                    <div className="round">
                      <input
                        type="checkbox"
                        id={"checkbox" + key}
                        onChange={(event) => props.handleChange(event)}
                        value={data.attributes.Name}
                      />
                      <label htmlFor={"checkbox" + key}></label>
                    </div>
                  </div>

                  <h4>{data.attributes.Name}</h4>
                </div>
              </label>
            )
          )}
      </div>
    </div>
  );
};

export default PropertyActivitiesSelect;
