import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBookingById, makeQuotation } from "../../Global/Api";
import Loading from "../../Global/Loading/Loading";

const DashboardBookingsEdit = () => {
  const [loading, setLoading] = useState(true);
  const [booking, setBookings] = useState("");
  const [roomPrice, setRoomPrice] = useState("");
  const [checkinDate, setCheckinDate] = useState();
  const [checkoutDate, setCheckoutDate] = useState();
  const [activitiesPrice, setActivitiesPrice] = useState("");
  const [formValues, setFormValues] = useState([]);
  const { bookingid } = useParams();
  const fetchBooking = async () => {
    try {
      const { data } = await getBookingById(
        sessionStorage.getItem("token"),
        bookingid
      );
      console.log(data);
      setBookings(data.data);
      setCheckinDate(data.data.attributes.check_out);
      setCheckoutDate(data.data.attributes.check_in);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { item_description: "", rate: "", quantity: "" },
    ]);
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    let value = e.target.value;
    if (e.target.name !== "item_description") {
      value = Number(value);
    }
    newFormValues[i][e.target.name] = value;
    setFormValues(newFormValues);
  };
  const handleQuoteSubmit = async (event) => {
    event.preventDefault();
    let data = {
      Room_Price: Number(roomPrice),
      Activities_Price: Number(activitiesPrice),
      booking: Number(bookingid),
      check_out: checkinDate,
      check_in: checkoutDate,
      quoteitems: formValues,
    };
    const res = await makeQuotation(sessionStorage.getItem("token"), data);
    console.log(res);
  };
  useEffect(() => {
    fetchBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="capitalize">
      <ul>
        <div>
          <h4> booking details</h4>
          {/* <li> atoll, island and property </li> */}
          <li>Number of Guests: {booking.attributes.number_of_guests}</li>
          <li>budget :{booking.attributes.number_of_guests}</li>
          <li>special requirements:{booking.attributes.number_of_guests}</li>
          {/* <li>status dropdown</li> */}
          {/* <li>activities</li> */}
          {/* <li>package</li> */}
          {/* <h4> guest details</h4> */}
          <li>guest name : {booking.attributes.Guest_Name}</li>
          {/* <li>nationality</li> */}
          {/* <li>passport number</li> */}
          <li>email id:{booking.attributes.email}</li>
          {/* <h4>travel details</h4> */}
          {/* <li>
            {" "}
            inbound flight number:{" "}
            {booking.attributes.Inbound_Flight.Flight_Number}
          </li> */}
          <li> inbound flight arrival time:</li>
          {/* <li>
            {" "}
            outbound flight number:
            {booking.attributes.Inbound_Flight.Flight_Number}
          </li> */}
          {/* <li> outbound flight departure time</li> */}
          <li>requested checkin date: {booking.attributes.check_in}</li>
          <li>requested checkout date:{booking.attributes.check_out}</li>
        </div>

        <div className="App">
          <form onSubmit={handleQuoteSubmit}>
            <div>
              <div>
                <label htmlFor="checkinday">check_in day:</label>
                <input
                  id="checkinday"
                  type="date"
                  value={checkinDate}
                  onChange={(event) => setCheckinDate(event.target.value)}
                />
              </div>
              <div>
                <label htmlFor="checkoutday">check_out day:</label>
                <input
                  id="checkoutday"
                  type="date"
                  value={checkoutDate}
                  onChange={(event) => setCheckoutDate(event.target.value)}
                />
              </div>
              <div>
                <input
                  name="room price"
                  value="room price"
                  placeholder="room price"
                  readOnly
                />
                <input
                  name="rate"
                  placeholder="Rate"
                  type="number"
                  min="0.00"
                  step="0.01"
                  defaultValue={activitiesPrice}
                  onChange={(event) => setActivitiesPrice(event.target.value)}
                />
              </div>
              <div>
                <input
                  name="activities cost"
                  value="activities cost"
                  placeholder="activities cost"
                  readOnly
                />
                <input
                  name="cost"
                  placeholder="cost"
                  type="number"
                  min="0.00"
                  step="0.01"
                  defaultValue={roomPrice}
                  onChange={(event) => setRoomPrice(event.target.value)}
                />
              </div>
            </div>
            {formValues.map((element, index) => (
              <div className="form-inline" key={index}>
                <input
                  type="text"
                  name="item_description"
                  placeholder="description"
                  value={element.item_description || ""}
                  onChange={(e) => handleChange(index, e)}
                />
                <input
                  type="Number"
                  name="rate"
                  placeholder="Rate"
                  value={element.rate || ""}
                  onChange={(e) => handleChange(index, e)}
                />
                <input
                  type="Number"
                  name="quantity"
                  placeholder="quantity"
                  value={element.quantity || ""}
                  onChange={(e) => handleChange(index, e)}
                />
                {/* {index ? ( */}
                <button
                  type="button"
                  className="button remove"
                  onClick={() => removeFormFields(index)}
                >
                  Remove
                </button>
                {/* ) : null} */}
              </div>
            ))}
            <div className="button-section">
              <button
                className="button add"
                type="button"
                onClick={() => addFormFields()}
              >
                Add
              </button>
            </div>
            <input
              className="dashboard-card-button"
              type="submit"
              value="quote"
            ></input>
          </form>
        </div>
      </ul>
    </div>
  );
};

export default DashboardBookingsEdit;
