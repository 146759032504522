import React from "react";
import "./TagLine.css";

const TagLine = (props) => {
  return (
    <div className="tagLine">
      <h1>{props.heading}</h1>
      <p>{props.paragraph}</p>
    </div>
  );
};

export default TagLine;
