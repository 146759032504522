import React from "react";
import "./PopularActivities.css";
import PopularActivityGrid from "./PopularActivityGrid";

const PopularActivities = (props) => {
  return (
    <div className="activity-grid-container">
      <h1>Popular Activities in Maldives</h1>
      <p className="section-p">{props.paragraph}</p>
      <PopularActivityGrid
        activities={props.activities}
        // specialActivities={props.specialActivities}
      />
    </div>
  );
};

export default PopularActivities;
