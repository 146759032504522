import React, { useEffect, useState } from "react";
import { search } from "../../components/Global/Api";
import { useParams, useNavigate, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./Results.css";
import placeHolder from "../../Assets/Images/mvstayplaceholder.jpg";
import Loading from "../../components/Global/Loading/Loading";

const Results = () => {
  const { query } = useParams();
  let navigate = useNavigate();
  const itemsPerPage = 15;
  const [atolls, setAtolls] = useState();
  const [islands, setIslands] = useState();
  const [properties, setProperties] = useState();
  const [output, setOutput] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [loading, setLoading] = useState(true);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % output.length;
    setItemOffset(newOffset);
  };

  const fetchResults = async () => {
    try {
      const { data, status } = await search(query);
      console.log(data);
      if (status === 200) {
        setAtolls(data.atolls);
        setIslands(data.islands);
        setProperties(data.properties);
      }
    } catch (error) {
      console.log(error);
      navigate("../404");
    }
  };

  useEffect(() => {
    let map = islands;

    if (islands !== undefined && islands !== null)
      map.forEach((island) => {
        let name = island.item.atoll.AtollCode + ". " + island.item.Name;
        let type = "Island";
        let description = island.item.Description;
        let score = island.score;
        let link =
          "/island/" + island.item.atoll.AtollCode + "/" + island.item.Name;
        let image =
          island.item.Featured_Image === null
            ? placeHolder
            : island.item.Featured_Image.url;
        setOutput((output) =>
          output.concat({
            Name: name,
            Type: type,
            Description: description,
            Image: image,
            Link: link,
            Score: score,
          })
        );
      });
    // eslint-disable-next-line
  }, [islands]);
  useEffect(() => {
    let map = atolls;

    if (atolls !== undefined && atolls !== null)
      map.forEach((atoll) => {
        let name = atoll.item.Name;
        let type = "Atoll";
        let description = atoll.item.Description;
        let score = atoll.score;

        let link = "/atoll/" + atoll.item.AtollCode;
        let image =
          atoll.item.Featured_Image === null
            ? placeHolder
            : atoll.item.Featured_Image.url;
        setOutput((output) =>
          output.concat({
            Name: name,
            Type: type,
            Description: description,
            Image: image,
            Link: link,
            Score: score,
          })
        );
      });
    // eslint-disable-next-line
  }, [atolls]);
  useEffect(() => {
    let map = properties;

    if (properties !== undefined && properties !== null)
      map.forEach((property) => {
        let name = property.item.Name;
        let type = property.item.Accomodation_Type;
        let description = property.item.Description;
        let score = property.score;

        let link = "/property/" + property.item.Name;
        let image = property.item.Featured_Image.url;
        setOutput((output) =>
          output.concat({
            Name: name,
            Type: type,
            Description: description,
            Image: image,
            Link: link,
            Score: score,
          })
        );
      });
    setLoading(false);

    // eslint-disable-next-line
  }, [properties]);

  useEffect(() => {
    output.sort((a, b) => a.Score - b.Score);
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(output.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(output.length / itemsPerPage));
    // eslint-disable-next-line
  }, [output, properties, islands, atolls, itemOffset, itemsPerPage]);
  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line
  }, []);
  if (loading) return <Loading />;
  return (
    <div>
      <h1 className="search-result-header">
        Search Results for<b> "{query}"</b>
      </h1>
      <div className="search-results">
        {output !== [] &&
          currentItems.map((data, key) => (
            <Link key={key} to={data.Link}>
              <div className="search-result">
                <div
                  className="result-image"
                  style={{ backgroundImage: "url(" + data.Image + ")" }}
                ></div>
                <div>
                  <p>{data.Name}</p>
                  {/* <p>{data.Description}</p> */}
                  <p>{data.Type}</p>
                </div>
              </div>
            </Link>
          ))}
      </div>

      <ReactPaginate
        containerClassName="pagination"
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default Results;
