import React from "react";
import DestinationsHeader from "../../components/DestinationsComponents/DestinationsHeader/DestinationsHeader";
import DestinationsList from "../../components/DestinationsComponents/DestinationsList/DestinationsList";
import "./Destinations.css";
import mapImage from "../../Assets/Images/Map Image.png";

const Destinations = () => {
  return (
    <div>
      <DestinationsHeader />
      <div className="destinations-content-wrapper">
        <DestinationsList />
        <div className="map-image-wrapper">
          <img className="map-image" src={mapImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Destinations;
