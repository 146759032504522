import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SvgIcon from "../../Global/SvgIcon";
import "./ActivityModalContent.css";

const ActivityModalContent = (props) => {
  const [activity, setActivity] = useState();
  const [islands, setIslands] = useState();

  useEffect(() => {
    setActivity(props.activity);
    setIslands(props.activity.islands.data);
  }, [props.activity]);

  if (activity === undefined) return null;
  return (
    <div className="activity-modal-content-wrapper">
      <div className="activity-modal-section-one">
        <div className="modal-featured-image-container">
          <div>
            <p>
              Activities {">"} {activity.Name}
            </p>
          </div>
          <div
            className="modal-featured-image"
            style={{
              backgroundImage:
                "url(" + activity.Image.data.attributes.url + ")",
            }}
          ></div>
        </div>
        <div className="modal-description">
          <h1>{activity.Name}</h1>
          <div className="atoll-modal-activity-icon">
            <SvgIcon
              className=""
              icon={activity && activity.svg_icon.data.attributes.SVGPath}
              viewBox={activity && activity.svg_icon.data.attributes.viewbox}
            />
          </div>
          <div className="modal-activity-description-text">
            <p>{activity.Description}</p>
          </div>
        </div>
      </div>
      <div className="activity-modal-section-two">
        <span>Popular Islands:</span>
        {islands &&
          islands.slice(0, 12).map((data, key) => (
            <Link
              className="modal-island-tag"
              to={
                "/island/" +
                data.attributes.atoll.data.attributes.AtollCode +
                "/" +
                data.attributes.Name
              }
              key={key}
            >
              <span>
                {data.attributes.atoll.data.attributes.AtollCode +
                  ". " +
                  data.attributes.Name}
              </span>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default ActivityModalContent;
