import React from "react";
import "./IslandInfo.css";
import IslandInfoWidget from "./IslandInfoWidget";

const IslandInfo = (props) => {
  console.log(props.activities);
  return (
    <div className="island-info">
      <div className="island-stats">
        <h3>
          Island Information
          <IslandInfoWidget population={props.population} />
        </h3>
      </div>
      <div className="island-tags">
        <h3>Activities in {props.name}</h3>
        <div className="island-activities">
          {props.activities?.data.length > 0 &&
            props.activities?.data.map((data, key) => (
              <span key={key}>{data.attributes.Name}</span>
            ))}
        </div>
        <h3>Popular Tags</h3>
        <div className="island-popular-tags">
          <span>Historical Landmark</span>
          <span>Frequent Whaleshark</span>
          <span>Tropical Vibe</span>
          <span>Eco-Friendly</span>
          <span>Bikini Beach</span>
        </div>
      </div>
    </div>
  );
};

export default IslandInfo;
