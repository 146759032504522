import React from "react";
import { Link } from "react-router-dom";
import bg from "../../Assets/Images/booking.jpg";
import logo from "../../Assets/Images/logo_white.png";
import "./Booking.css";
import Lottie from "react-lottie";
import * as Animation from "./success.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";

const ThankYou = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      className="booking-page"
      style={{
        backgroundImage: "url(" + bg + ")",
      }}
    >
      <div className="booking-box">
        <img className="logo" alt="MVSTAY" src={logo} />
        <h1 className="britania">THANK YOU FOR YOUR REQUEST!</h1>

        <Lottie options={defaultOptions} height={150} width={150} />
        <p className="submit-instruction">
          We will send you a personalized quote within 4 working days.
        </p>
        <Link className="back-link" to={"/"}>
          <FontAwesomeIcon icon={faLeftLong} /> Back to Homepage
        </Link>
      </div>
    </div>
  );
};

export default ThankYou;
